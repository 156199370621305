// import "./App.css";
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { extend, useLoader } from "@react-three/fiber";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Physics, Debug, RigidBody, CuboidCollider } from "@react-three/rapier";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import {
  OrbitControls,
  Stats,
  useHelper,
  Sky,
  Float,
  Environment,
} from "@react-three/drei";
import * as THREE from "three";
import Model from "./components/Model";
import Mediotubo from "./components/Mediotubo";
import Personaje from "./components/Personaje";
import { Suspense } from "react";
import {
  CSS3DObject,
  CSS3DRenderer,
} from "three/examples/jsm/renderers/CSS3DRenderer";
import ReactDOM from "react-dom";
import CanvasCSS from "./Canvas/CanvasCSS";
import CartelPrueba from "./Carteles/CartelPrueba";
import Lights from "./Luces/Lights";
import { Button, Modal, Spinner } from "react-bootstrap";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import nipplejs from "nipplejs";
import TextoSoweme from "./components/TextoSoweme";
import { isAndroid, isIOS } from "mobile-device-detect";
import Baranda1 from "./Barandas/baranda1";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import GrupoRampas from "./components/GrupoRampas";
import Cronometro from "./components/Cronometro";
import Loader from "./components/Loader";
import { View } from "@react-three/drei";
import GrupoBarandas from "./components/GrupoBarandas";
import StopWatch from "./components/CronometroFinal";
import imagenCoin from "./imagenes/coin.png";
import Confetti from "react-confetti";
import CartelMensaje from "./Carteles/CartelMensaje";
import { useResizeDetector } from "react-resize-detector";
import { useProgress } from "@react-three/drei";
import LoadingBar from "./componentesLayout/Loading";
import Button1 from "./componentesLayout/Button1";
import Button2 from "./componentesLayout/Button1-2";
import "./App.css";
import "./keys.css";
function App() {
  // const BASE_URL = "http://localhost:3120";
  const BASE_URL = "https://names.gerardo-duckwitz.com";
  const totalProgress = 39;
  const [objetivo, setObjetivo] = useState([0, 0, 0]);
  const [refCartel, setRefCartel] = useState(null);
  const ref = useRef(null);
  const refRendererCSS = useRef(null);
  const refControls = useRef(null);
  const [controls, setControls] = useState(null);
  const [personajePiso, setPersonajePiso] = useState(false);
  const [creado, setCreado] = useState(false);
  const [mostrarPersonaje, setMostrarPersonaje] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [orientation, setOrientation] = useState("");
  const [loadingFullScreen, setLoadingFullScreen] = useState(false);
  const [dispositivo, setDispositivo] = useState("");
  const [aspect, setAspect] = useState(0);
  const [manager, setManager] = useState(null);
  const [Os, setOs] = useState("android");
  const [alturaDiv, setAlturaDiv] = useState("auto");
  // const [arrayPersonajePiso, setArrayPersonajePiso] = useState([]);
  const [touchJump, setTouchJump] = useState(false);
  const [personajeRampa, setPersonajeRampa] = useState(false);
  const [personajePisoGeneral, setPersonajePisoGeneral] = useState(false);
  const [tipoRampa, setTipoRampa] = useState("");
  const [skateGirar, setSkateGirar] = useState(false);
  const [activarTimer, setActivarTimer] = useState(false);
  const [cantidadCoins, setCantidadCoins] = useState(0);
  const [gano, setGano] = useState(false);
  const [termino, setTermino] = useState(false);
  const [loading, setLoading] = useState(true);
  const [porcentaje, setPorcentaje] = useState(0);
  const [ocultarDiv, setOcultarDiv] = useState(false);
  const [personajePisoFinal, setPersonajePisoFinal] = useState(false);
  const [bug, setBug] = useState(false);
  const [timerBug, setTimerBug] = useState(0);
  const [resetearPersonaje, setResetearPersonaje] = useState(false);
  const [tiempo, setTiempo] = useState(0);
  const [stopTimer, setStopTimer] = useState(false);
  const [coinsParaGanar, setCoinsParaGanar] = useState(8);
  const [show, setShow] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight);

  const refDivScroll = useRef(null);
  const refJoystick = useRef(null);
  const refEstadoInit = useRef(true);
  const refArrayPersonajePiso = useRef([]);
  const refDivContenedor = useRef(null);
  const refDivTamanio = useRef(null);
  const refEstabaEnRampa = useRef(false);
  const refTimeOutPersonaje = useRef(null);
  const refAnteriorRampa = useRef(null);
  const refGano = useRef(false);
  const refDivEmpezar = useRef(null);
  const refAudioSkateRunning = useRef(null);
  const { active, progress, errors, item, loaded, total } = useProgress();
  const refCambioEstado = useRef(0);
  const refContadorBug = useRef(0);
  const refBug = useRef(false);
  const refPersonajePisoGeneral = useRef(false);
  const refPersonajePisoFinal = useRef(false);
  const refTiempo = useRef(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    // console.log("item", item);
    // console.log("loaded", loaded);
    // console.log("total", total);
    //34 el total
    // console.log("progress", progress);
    const temporal = (loaded * 100) / totalProgress;
    setPorcentaje(temporal);

    // if (parseInt(loaded) === 35) {
    //   setLoading(false);
    // }
  }, [item, loaded, total, progress]);

  // useEffect(() => {
  //   if (parseInt(porcentaje) === 100) {
  //     setLoading(false);
  //   }
  // }, [porcentaje]);
  const texturaFloor = useLoader(TextureLoader, "./texturas/cemento.jpg");
  texturaFloor.repeat.x = 19;
  texturaFloor.repeat.y = 19;
  texturaFloor.wrapS = THREE.RepeatWrapping;
  texturaFloor.wrapT = THREE.RepeatWrapping;
  const {
    width,
    height,
    ref: refDivContenedor2,
  } = useResizeDetector({
    onResize: useCallback(() => {
      // on resize logic
      // console.log("resize div");
      // console.log("width", width);
      // console.log("height", height);
    }, []),
  });
  useEffect(() => {
    if (!width || !height) return;
    // console.log("width", width);
    // console.log("height", height);

    if (isMobile) return;
    if (isAndroid) {
      setAspect(window.innerWidth / window.innerHeight);
    } else if (isIOS) {
      setAspect(width / height);
    } else {
      setAspect(width / height);
    }
  }, [width, height]);
  // const CameraController = () => {
  //   const { camera, gl } = useThree();
  //   useEffect(() => {
  //     refControls.current = new OrbitControls(camera, gl.domElement);

  //     refControls.current.minDistance = 1;
  //     refControls.current.maxDistance = 200;
  //     refControls.current.target(3, 0, 0);
  //     // setControls(refControls.current);
  //     // gl.logarithmicDepthBuffer = true;
  //     gl.alphaTest = true;
  //     // return () => {
  //     //   refControls.current.dispose();
  //     // };
  //   }, [camera, gl]);
  //   return null;
  // };

  function DOMObject({ dom }) {
    const { scene } = useThree();
    const ref = useRef(null);
    useFrame(() => (ref.current.rotation.x = ref.current.rotation.y += 0.01));
    useEffect(() => {
      ref.current = new CSS3DObject(dom.current);
      // console.log("ref es", ref.current);
      ref.current.position.set(0, 1, 0);
      // ref.current.scale.set(0.1, 0.1, 0.1);
      scene.add(ref.current);
      // return () => scene.remove(ref.current);
    }, [dom, scene]);
    return null;
  }
  function Portal({ children }) {
    const root = useMemo(() => document.createElement("div"), []);
    return ReactDOM.createPortal(
      <React.Fragment>{children}</React.Fragment>,
      root
    );
  }
  let enviado = false;
  // const { gl } = useThree();
  // useEffect(() => {
  //   if (enviado) return;
  //   enviado = true;
  //   refRendererCSS.current = new CSS3DRenderer();
  //   refRendererCSS.current.setSize(window.innerWidth, window.innerHeight);
  //   refRendererCSS.current.domElement.style.position = "absolute";
  //   refRendererCSS.current.domElement.style.top = 0;
  //   refRendererCSS.current.domElement.id = "prueba1";
  //   gl.appendChild(refRendererCSS.current.domElement);
  // }, []);

  const sendObjetivo = (valor) => {
    // console.log("valor es", valor.position);
    setObjetivo(valor?.position);
  };
  const sendRef = (valor) => {
    if (valor) {
      setRefCartel(valor);
    }
  };

  // useEffect(() => {
  //   if (creado) {
  //     setTimeout(() => {
  //       setMostrarPersonaje(true);
  //     }, 2000);
  //   }
  // }, [creado]);
  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };
    onFullscreenChange();
    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  useEffect(() => {
    // console.log("isFullscreen es", isFullscreen);

    if (!isFullscreen) {
    }
  }, [isFullscreen]);
  function openFullscreen() {
    if (Os === "ios") {
      setIsFullscreen(true);

      return;
    }
    setLoadingFullScreen(true);
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullScreen) {
      /* Safari */
      elem.webkitRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    } else if (elem.webkitEnterFullScreen) {
      elem.webkitEnterFullScreen();
    }
    // webkitEnterFullscreen
    // if (elem.webkitFullscreenElement) {
    //   elem.webkitCancelFullScreen();
    // } else {
    //   const el = elem.documentElement;
    //   el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    // }
  }

  useEffect(() => {
    // window.screen.addEventListener("change", () => {
    //   console.log(
    //     `The orientation of the window.screen is: ${window.screen.orientation}`
    //   );
    //   const orientacion = window.screen.orientation;
    //   if (orientacion.includes("landscape")) {
    //     alert("landscape");
    //   } else {
    //     alert("portrait");
    //   }
    // });

    let portrait = window.matchMedia("(orientation: portrait)");
    if (portrait.matches) {
      // console.log("portrait");
      setOrientation("portrait");
    } else {
      // console.log("landscape");
      setOrientation("landscape");
    }
    const funcionChangeOrientation = () => {
      // console.log("dispositivo es", dispositivo);
      if (isBrowser) return;
      let portrait = window.matchMedia("(orientation: portrait)");
      const width = refDivTamanio.current.clientWidth;
      const height = refDivTamanio.current.clientHeight;
      if (isAndroid) {
        setAspect(window.innerWidth / window.innerHeight);
      } else if (isIOS) {
        setAspect(width / height);
      } else {
        setAspect(width / height);
      }
      //Landscape es horizontal
      if (portrait.matches) {
        // Portrait mode
        // console.log("portrait");
        setOrientation("portrait");
      } else {
        // Landscape
        // console.log("landscape");
        setOrientation("landscape");
      }
    };

    portrait.addEventListener("change", funcionChangeOrientation);

    function fullscreenchanged(event) {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (document.fullscreenElement) {
        // console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
        setLoadingFullScreen(false);
      } else {
        setLoadingFullScreen(false);
        // console.log("Leaving fullscreen mode.");
      }
    }

    document.addEventListener("fullscreenchange", fullscreenchanged);

    const funcionResize = (e) => {
      // if (window.matchMedia("(pointer: coarse)").matches) {
      // setDispositivo("mobile");
      const width = refDivTamanio.current.clientWidth;
      const height = refDivTamanio.current.clientHeight;
      // const ancho = refDivContenedor.current.clientWidth;
      // const alto = refDivContenedor.current.clientHeight;
      // const div1 = document.querySelector("#prueba1");
      // if (!div1) return;
      // setWidth(ancho);
      // setHeight(alto);
      if (isAndroid) {
        setAspect(window.innerWidth / window.innerHeight);
      } else if (isIOS) {
        // console.log("width es", width);
        // console.log("height es", height);
        setAspect(width / height);
      } else {
        setAspect(width / height);
      }
      // setAspect(1.8);
      // alert("aspect es" + aspect);
      // refDivContenedor.current.style.aspectRatio = aspect;
      // } else {
      // setDispositivo("desktop");
      // refDivContenedor.current.style.aspectRatio = 2 / 1;
      // }
    };
    funcionResize();
    // if (document.readyState === "complete") {
    //   funcionResize();
    // } else {
    // }
    window.addEventListener("resize", funcionResize);
    if (window.matchMedia("(pointer: coarse)").matches) {
      // setDispositivo("mobile");
    } else {
      // setDispositivo("desktop");
    }

    return () => {
      window.removeEventListener("resize", funcionResize);
      portrait.removeEventListener("change", funcionChangeOrientation);
      document.removeEventListener("fullscreenchange", fullscreenchanged);
    };
  }, []);

  useEffect(() => {
    // alert(dispositivo);
    if (isMobile) {
      refDivContenedor.current.style.aspectRatio = aspect;
    } else {
      refDivContenedor.current.style.aspectRatio = 2 / 1;
    }
  }, [dispositivo, aspect]);

  /**
   *
   *
   * Joystick
   *
   *
   */
  useEffect(() => {
    if (!isMobile || !ocultarDiv) return;
    const temporal = nipplejs.create({
      zone: refJoystick.current,
      size: 120,
      multitouch: true,
      maxNumberOfNipples: 2,
      mode: "static",
      restJoystick: true,
      shape: "circle",
      // position: { top: 20, left: 20 },
      // position: { top: "60px", left: "60px" },
      dynamicPage: true,
      color: "#FFFFFF",
    });
    setManager(temporal);
  }, [isMobile, ocultarDiv]);

  const funcionActualizarPiso = (valor) => {
    const { id: idValor, estado, tipo } = valor;
    // console.log("se ejecuta");
    const temporal = refArrayPersonajePiso.current.find(
      (i) => parseInt(i.id) === parseInt(idValor)
    );
    if (!temporal) {
      refArrayPersonajePiso.current = [
        ...refArrayPersonajePiso.current,
        { id: idValor, piso: estado, tipo: tipo },
      ];
    } else {
      // const arrayTemporal = refArrayPersonajePiso.current.map((i) => {
      //   if (parseInt(i.id) === parseInt(idValor)) {
      //     i.piso = estado;
      //   }
      //   return i;
      // });
      // refArrayPersonajePiso.current = arrayTemporal;
      const index = (element) => parseInt(element.id) === parseInt(idValor);
      const index2 = refArrayPersonajePiso.current.findIndex(index);
      refArrayPersonajePiso.current[index2].piso = estado;
      refArrayPersonajePiso.current[index2].tipo = tipo;
    }
    const temporal3 = refArrayPersonajePiso.current.find(
      // (i) => (i.piso === true && i.tipo === "piso") || i.tipo === "baranda"
      (i) => i.piso === true && i.tipo === "piso"
    );
    // console.log("temporal3", temporal3);
    // console.log("refArrayPersonajePiso", refArrayPersonajePiso.current);
    if (temporal3) {
      setPersonajePiso(true);
    } else {
      setPersonajePiso(false);
    }

    /**
     *
     * Verificar si esta en una rampa para desactivar forward
     *
     */

    const temporal4 = refArrayPersonajePiso.current.find(
      (i) => i.piso === true && i.tipo.includes("rampa")
    );
    // console.log("temporal4", temporal4);
    if (temporal4) {
      setTipoRampa(temporal4.tipo);
      // refEstabaEnRampa.current = true;
      // refAnteriorRampa.current = temporal4;
      setPersonajeRampa(true);
      // if (refTimeOutPersonaje.current) {
      //   clearTimeout(refTimeOutPersonaje.current);
      // }
      // refTimeOutPersonaje.current = setTimeout(() => {
      //   if (
      //     refEstabaEnRampa.current &&
      //     parseInt(temporal4.id) === parseInt(refAnteriorRampa.current.id)
      //   ) {
      //     setPersonajeRampa(true);
      //   }
      // }, 250);
    } else {
      // if (refEstabaEnRampa.current) {
      //Estaba en rampa
      // console.log("estaba en rampa");
      //   setPersonajeRampa(true);
      // } else {
      // }
      setPersonajeRampa(false);
      refEstabaEnRampa.current = false;
    }
    /**
     *
     * Piso rampa.
     *
     */

    const temporal5 = refArrayPersonajePiso.current.find(
      (i) => i.piso === true && i.tipo !== "personaje"
      // (i) => i.piso === true
    );

    if (temporal5) {
      setPersonajePisoGeneral(true);
    } else {
      setPersonajePisoGeneral(false);
    }

    /**
     *
     *
     *PersonajePisoFinal
     *
     *
     */
    const temporal6 = refArrayPersonajePiso.current.find(
      (i) => i.piso === true
      // (i) => i.piso === true
    );

    if (temporal6) {
      setPersonajePisoFinal(true);
    } else {
      setPersonajePisoFinal(false);
    }
    if (refContadorBug.current > 2000) {
      refContadorBug.current = 0;
    } else {
      refContadorBug.current = refContadorBug.current + 1;
    }
    refPersonajePisoGeneral.current = temporal5;
    refPersonajePisoFinal.current = temporal6;
    if (!temporal5 && !temporal6) {
      verificarBug(temporal5, temporal6, refContadorBug.current);
    }

    // console.log("temporal5", temporal5);
    // console.log("temporal6", temporal6);
    // setTimeout(() => {
    //   if (temporal5 && temporal6) {
    //     funcionActivarBug();
    //   }
    // }, 3500);

    // console.log("refArraypersonajePiso", refArrayPersonajePiso.current);
  };

  const verificarBug = async (pisoGeneral, pisoFinal, contador) => {
    // console.log("entra en bug");
    await delay(3500);

    if (
      // !pisoGeneral &&
      // !pisoFinal &&
      !refPersonajePisoGeneral.current &&
      !refPersonajePisoFinal.current &&
      contador === refContadorBug.current
      //  &&
      // refCambioEstado.current === refContadorBug.current &&
      // refBug.current
    ) {
      console.log(
        "refPersonajePisoGeneral.current",
        refPersonajePisoGeneral.current
      );
      console.log(
        " refPersonajePisoFinal.current",
        refPersonajePisoFinal.current
      );
      console.log("contador", contador);
      console.log("refContadorBug.current", refContadorBug.current);
      // console.log("refCambioEstado", refCambioEstado.current);
      // console.log("refContadorBug", refContadorBug.current);
      // console.log("bug");
      if (document.hasFocus()) {
        funcionActivarBug();
      }
    } else {
      // refContadorBug.current = 0;
      refCambioEstado.current = false;
    }
  };
  useEffect(() => {
    // console.log("entra en funcion1");
    // refContadorBug.current = refContadorBug.current + 1;
    // refCambioEstado.current = refContadorBug.current;
    // if (!personajePisoGeneral && !personajePisoFinal) {
    //   refBug.current = true;
    //   verificarBug();
    // } else {
    //   refContadorBug.current = refContadorBug.current + 1;
    //   refBug.current = false;
    // }
  }, [personajePisoGeneral, personajePisoFinal]);
  const funcionActivarBug = async () => {
    if (bug) return;
    setBug(true);
    setTimerBug(3);
    await delay(1000);
    setTimerBug(2);
    await delay(1000);

    setTimerBug(1);
    await delay(1000);
    window.location.reload();
    // setResetearPersonaje(true);
    // await delay(1000);
    // setTimerBug(0);
    // setBug(false);
    // setResetearPersonaje(false);
  };
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const getMobileOS = () => {
    // const ua = navigator.userAgent;
    // if (/android/i.test(ua)) {
    //   setOs("android");
    // } else if (
    //   /iPad|iPhone|iPod/.test(ua) ||
    //   (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    // ) {
    //   setOs("ios");
    // }
    // setOs("otro");
    if (isAndroid) {
      setOs("android");
    } else if (isIOS) {
      setOs("ios");
    } else {
      setOs("otro");
    }
  };
  useEffect(() => {
    getMobileOS();
  }, []);
  // This will run one time after the component mounts
  // useEffect(() => {
  //   // callback function to call when event triggers
  //   window.scrollTo(0, document.body.clientHeight);
  //   const onPageLoad = () => {
  //     // console.log('page loaded');
  //     // setLoading(false);
  //     //Scroll to bottom of page
  //   };

  //   // Check if the page has already loaded
  //   if (document.readyState === "complete") {
  //     setTimeout(onPageLoad, 50);
  //   } else {
  //     window.addEventListener("load", onPageLoad);
  //     // Remove the event listener when component unmounts
  //     // return () => window.removeEventListener("load", onPageLoad);
  //   }
  // }, []);
  useEffect(() => {
    if (isMobile) {
      setAlturaDiv("100%");
    } else {
      setAlturaDiv("100vh");
    }
  }, [isMobile]);

  useEffect(() => {
    // const width = refDivTamanio.current.clientWidth;
    // const height = refDivTamanio.current.clientHeight;
    // if (isAndroid) {
    //   setAspect(window.innerWidth / window.innerHeight);
    // } else if (isIOS) {
    //   setAspect(width / height);
    // } else {
    //   setAspect(width / height);
    // }
  }, [alturaDiv]);

  useEffect(() => {
    // console.log("isMobile", isMobile);
    // if (isMobile) {
    //   setDispositivo("mobile");
    // } else {
    //   setDispositivo("desktop");
    // }
  }, [isMobile]);
  const girarTabla = (valor) => {
    if (valor) {
      setSkateGirar(true);
    } else {
      setSkateGirar(false);
    }
  };
  const funcionActivarTimer = (valor) => {
    if (valor) {
      setActivarTimer(true);
    }
  };
  const contadorCoins = () => {
    // console.log("contadorCoins");
    const temporal = cantidadCoins;
    setCantidadCoins(temporal + 1);
  };
  useEffect(() => {
    // console.log("cantidadCoins", cantidadCoins);
    // console.log("coinsParaGanar", coinsParaGanar);
    // console.log("termino", termino);
    if (parseInt(cantidadCoins) === coinsParaGanar && !termino) {
      setStopTimer(true);
      setTimeout(() => {
        setGano(true);
        refGano.current = true;
        setTermino(false);
      }, 1500);
    } else {
      // setGano(false);
    }
    // setGano(true);
  }, [cantidadCoins, termino]);

  const sendControls = (valor) => {
    setControls(valor);
  };
  const sendCompletoNombre = (valor, nombre) => {
    // console.log("gano en app", refGano.current);
    if (!refGano.current) return;
    if (valor) {
      refGano.current = false;
      setGano(false);

      setTermino(true);

      /**
       *
       * Aca hago el fetch al backend
       *
       */
      // console.log("nombre", nombre);
      let tipo = "pc";
      if (isMobile) {
        tipo = "mobile";
      }
      funcionEnviarDatos(nombre, tipo);
    }
  };
  const funcionEnviarDatos = async (nombre, tipo) => {
    try {
      await fetch(`${BASE_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombre: nombre,
          tipo: tipo,
          tiempo: refTiempo.current,
        }),
      });
    } catch (error) {
      // console.log("error es", error);
    }
  };
  const sendTerminoLoading = (valor) => {
    if (valor) {
      setLoading(false);
    }
  };

  const sendTiempo = (valor) => {
    setTiempo(valor);
    refTiempo.current = valor;
  };

  useEffect(() => {
    if (isMobile) {
      setCoinsParaGanar(4);
    } else {
      setCoinsParaGanar(8);
    }
  }, [isMobile]);
  return (
    <div>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "-10",
          background: "white",
          opacity: "0",
        }}
        ref={refDivTamanio}
        id="div-tamanio"
      ></div>

      {creado && (
        <CartelPrueba
          sendRef={sendRef}
          sendCompletoNombre={sendCompletoNombre}
          gano={gano}
          tiempo={tiempo}
        />
      )}
      {loading && (
        <div
          style={{
            zIndex: "1000",
            background: "#FFFFFF",
            width: "100%",
            height: "100vh",
            position: "fixed",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <LoadingBar
            porcentaje={porcentaje}
            sendTerminoLoading={sendTerminoLoading}
          />
        </div>
      )}

      {!isFullscreen && orientation === "landscape" && isMobile && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
            zIndex: "100",
            position: "fixed",
            background: "#FFFFFF",
            width: "100%",
            // display: !isFullscreen && isMobile ? "block" : "none",
          }}
        >
          <div className="text-center">
            {/* <p>Go to fullscreen</p> */}
            {/* {Os === "android" && <p>Tienes android</p>}
            {Os === "ios" && <p>Tienes iOS</p>} */}
            {loadingFullScreen && (
              <Spinner
                animation="border"
                className="mt-2 mb-2 mx-auto d-block"
              />
            )}

            <p>Reduce track</p>
            {/* <Button2 onClick={openFullscreen}>Play</Button2> */}
            <Button
              className="boton-agregar"
              size="lg"
              onClick={openFullscreen}
            >
              Go!
            </Button>
          </div>
        </div>
      )}
      {!isFullscreen && orientation === "portrait" && isMobile && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
            zIndex: "100",
            position: "fixed",
            background: "#FFFFFF",
            width: "100%",
          }}
        >
          <div className="text-center">
            <p>Please rotate the mobile</p>
          </div>
        </div>
      )}
      {isFullscreen && orientation === "portrait" && isMobile && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100vh",
            zIndex: "100",
            position: "fixed",
            background: "#FFFFFF",
            width: "100%",
          }}
        >
          <div className="text-center">
            <p>Please rotate the mobile</p>
          </div>
        </div>
      )}

      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: alturaDiv,
            width: "100%",
            aspectRatio: aspect,
            zIndex: "50",
          }}
        >
          <div
            style={{
              width: "100%",
              // height: "auto",
              // height: "100%",
              position: "relative",
              // aspectRatio: "2/1",
            }}
            id="div-contenedor"
            ref={(ref) => {
              refDivContenedor.current = ref;
              refDivContenedor2.current = ref;
            }}
          >
            {bug && (
              <div
                style={{
                  width: "100%",
                  // height: "100%",
                  background: "rgba(0,0,0,0.5)",
                  position: "absolute",
                  zIndex: "60",
                  aspectRatio: aspect ? aspect : "2/1",
                }}
                className="d-flex   flex-column align-items-center "

                // ref={refDivEmpezar}
              >
                <h3
                  className="text-center"
                  style={{
                    color: "#FFFD55",
                    fontSize: "40px",
                    fontWeight: "bold",
                    marginTop: "21vh",
                  }}
                >
                  Bug detected or you are out of track
                </h3>
                <p
                  className="text-center"
                  style={{
                    color: "#FFFD55",
                    fontSize: "35px",
                    fontWeight: "bold",
                    marginTop: "10vh",
                  }}
                >
                  Reloading website in {timerBug}
                </p>
                <br />
              </div>
            )}
            {!ocultarDiv && (
              <div
                style={{
                  width: "100%",
                  height: height,
                  background: "rgba(0,0,0,0.5)",
                  position: "absolute",
                  zIndex: "60",
                  aspectRatio: aspect ? aspect : "2/1",
                }}
                className="d-flex   flex-column"

                // ref={refDivEmpezar}
              >
                <h3
                  className="text-center"
                  style={{
                    color: "#FFFD55",
                    fontSize: "40px",
                    fontWeight: "bold",
                    marginTop: "10vh",
                  }}
                >
                  Collect the coins!
                </h3>
                <Button2
                  onClick={() => {
                    setOcultarDiv(true);

                    setMostrarPersonaje(true);
                  }}
                  style={{
                    marginTop: "10vh",
                  }}
                >
                  Play
                </Button2>
                {!isMobile && (
                  <p className="text-center text-white mt-5">
                    Works ok in high computer
                  </p>
                )}
                {isMobile && (
                  <p className="text-center text-white mt-3">
                    Works ok in high mobile
                  </p>
                )}
                {!isMobile && (
                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{
                      width: "100%",
                      // height: "100%",
                      height: height ? height / 2 : "200px",
                      // aspectRatio: aspect,
                    }}
                    ref={refDivEmpezar}
                    id="div-empezar"
                  >
                    <div
                      className=" d-flex justify-content-center "
                      // style={{ eys d-flex justify-content-center "
                      // style={{ width: "100%", height: "100%" }}
                    >
                      <div className="keys ">
                        <div
                          data-key="65"
                          className="key__button"
                          style={{
                            marginLeft: "-32px",
                            marginBottom: "8px",
                            display: "block",
                          }}
                        >
                          W
                        </div>
                        <div
                          // data-key="65"
                          className="key__button"
                          style={{ marginLeft: "-88px", marginTop: "8px" }}
                        >
                          A
                        </div>
                        <div
                          data-key="65"
                          className="key__button"
                          style={{ marginTop: "8px" }}
                        >
                          S
                        </div>
                        <div
                          data-key="65"
                          className="key__button"
                          style={{ marginTop: "8px" }}
                        >
                          D
                        </div>
                        <div
                          data-key="65"
                          className="key__button"
                          style={{ width: "300px" }}
                        >
                          Space
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="d-flex align-items-center justify-content-center"
                  // style={{ height: "100px" }}
                >
                  <p
                    className="text-center text-white"
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      position: "absolute",
                      bottom: "0px",
                    }}
                    onClick={handleShow}
                  >
                    Credits
                  </p>
                </div>
              </div>
            )}
            {/* <div
              ref={refDivScroll}
              style={{
                position: "absolute",
                bottom: "-400px",
                width: "100%",
                background: "red",
                height: "200px",
                zIndex: "999999",
              }}
            ></div> */}
            <Canvas
              shadows
              camera={{
                fov: 75,
                near: 0.1,
                far: 10000,
                //  position: [0, 0, 5]
              }}
              onCreated={({ gl, camera }) => {
                setCreado(true);
                camera.lookAt(0, 0, 0);
              }}
              // frameloop="demand"
              // performance={{ max: 1 }}
            >
              {creado && refCartel && (
                <CanvasCSS cartel1={refCartel} sendControls={sendControls} />
              )}
              {/* <Stats /> */}
              {/* <mesh>
          <torusKnotGeometry />
          <meshNormalMaterial />
          </mesh> */}
              {/* <mesh position={[2, 0, 0]} scale={1.5}></mesh> */}
              {/* <CameraController /> */}
              {/* <OrbitControls panSpeed={0.1} enableRotate={false} /> */}
              <Sky azimuth={1} />
              {/* <Physics gravity={[0, -9.8, 0]} timeStep="vary"> */}

              {creado && (
                <Suspense fallback={null}>
                  <Physics
                    // gravity={[0, -14, 0]}
                    gravity={[0, -10, 0]}
                    timeStep="vary"
                    // interpolate={false}S
                  >
                    {/* <Debug color="green" /> */}
                    {/* <Html occlude="blending" position={[0, 4, 0]}>
                      <div
                        style={{
                          background: "red",
                          height: "100px",
                          width: "100px",
                        }}
                      >
                        Hola numero uno!
                      </div>
                    </Html> */}
                    {/* <Html castShadow receiveShadow occlude="blending" transform>
                      <iframe
                        title="embed"
                        width={700}
                        height={500}
                        src="https://threejs.org/"
                        frameBorder={0}
                      />
                    </Html> */}
                    {/* <Float floatIntensity={10} rotationIntensity={4}>

                      <Html
                        castShadow
                        receiveShadow
                        occlude="blending"
                        transform
                      >
                        <iframe
                          title="embed"
                          width={700}
                          height={500}
                          src="https://threejs.org/"
                          frameBorder={0}
                        />
                      </Html>
                    </Float> */}
                    {/* <Layout /> */}

                    {/* 🧊 cube */}
                    {/* <RigidBody position-y={1} type="dynamic" position={[0, 20, 6]}>
              <mesh castShadow>
                <boxGeometry args={[2, 2, 2]} />
                <meshStandardMaterial color="green" />
              </mesh>
            </RigidBody> */}
                    <ambientLight intensity={0.8} />
                    {/* 🏀 ball */}
                    {/* <mesh>
            <torusKnotGeometry />
            <meshNormalMaterial />
            </mesh> */}

                    {/* Ground  Floor*/}
                    {/* 1 */}
                    <RigidBody
                      type="fixed"
                      position-y={-0.1 / 2}
                      rotation={[-Math.PI / 2, 0, 0]}
                      colliders={"cuboid"}
                      name="piso"
                      onCollisionEnter={(event) => {
                        // console.log(
                        //   "Collision at world position ",
                        //   event.manifold.solverContactPoint(0)
                        // );
                        // console.log("collision enter piso es", event);
                        if (
                          event.rigidBody.userData.name === "personaje"
                          //  &&
                          // event.rigidBodyObject.position.y < 0.8
                        ) {
                          // console.log("personaje esta en el piso");
                          // console.log("estado init es", refEstadoInit.current);
                          // if (!refEstadoInit.current) {
                          // console.log("se asigno true a persona piso");
                          // setPersonajePiso(true);

                          funcionActualizarPiso({
                            id: 1,
                            estado: true,
                            tipo: "piso",
                          });
                          // }
                        } else {
                          // console.log("entro aca 2");
                          // setPersonajePiso(false);
                          // funcionActualizarPiso({ id: 1, estado: false });
                        }
                        refEstadoInit.current = false;
                        if (event.rigidBodyObject) {
                          // console.log(
                          //   event.rigidBodyObject.name,
                          //   " collided with ",
                          //   event.rigidBodyObject.name
                          // );
                        }
                      }}
                      onCollisionExit={(event) => {
                        // console.log("event es", event);
                        // console.log("entro aca 3");
                        // console.log("collision exit piso", event);
                        if (
                          event.rigidBody.userData.name === "personaje"
                          //  &&
                          // event.rigidBodyObject.position.y > 0.49
                        ) {
                          // setPersonajePiso(false);
                          // console.log("entra en false2");
                          funcionActualizarPiso({
                            id: 1,
                            estado: false,
                            tipo: "piso",
                          });
                        }
                      }}
                    >
                      <mesh receiveShadow>
                        <boxGeometry args={[500, 500, 1]} />
                        <meshStandardMaterial
                          // color="blue"
                          opacity={0.8}
                          map={texturaFloor}
                        />
                      </mesh>
                    </RigidBody>
                    {/* 2 */}
                    {/* <RigidBody
                    type="fixed"
                    // position-y={0.47}
                    position={[0, 0.4, 90]}
                    // rotation={[-Math.PI / 2, 0, 0]}
                    colliders={"hull"}
                    onCollisionEnter={(event) => {
                      if (event.rigidBody.userData.name === "personaje") {
                        funcionActualizarPiso({ id: 2, estado: true });
                      }
                    }}
                    onCollisionExit={(event) => {
                      // console.log("collision exit3", event);
                      if (event.rigidBody.userData.name === "personaje") {
                        funcionActualizarPiso({ id: 2, estado: false });
                      }
                    }}
                    friction={0}
                  >
                    <Model />
                  </RigidBody> */}

                    {/* 2 Baranda */}
                    <GrupoRampas
                      funcionActualizarPiso={funcionActualizarPiso}
                      positionGroup={[-5, 2, 90]}
                      tipoRampa={tipoRampa}
                      contadorCoins={contadorCoins}
                    />
                    <GrupoBarandas
                      funcionActualizarPiso={funcionActualizarPiso}
                      positionGroup={[70, 0, 50]}
                      rotationGroup={[0, Math.PI * 0.5, 0]}
                      girarTabla={girarTabla}
                      contadorCoins={contadorCoins}
                    />
                    {!isMobile && (
                      <>
                        <GrupoRampas
                          funcionActualizarPiso={funcionActualizarPiso}
                          positionGroup={[10, 2, -55]}
                          rotationGroup={[0, Math.PI, 0]}
                          tipoRampa={tipoRampa}
                          contadorCoins={contadorCoins}
                        />
                        <GrupoBarandas
                          funcionActualizarPiso={funcionActualizarPiso}
                          positionGroup={[-70, 0, 0]}
                          rotationGroup={[0, Math.PI * 1.5, 0]}
                          girarTabla={girarTabla}
                          contadorCoins={contadorCoins}
                        />
                      </>
                    )}
                    <CartelMensaje />
                    {/* Texto */}
                    <TextoSoweme />
                    {creado && (
                      <Personaje
                        controls={controls}
                        sendObjetivo={sendObjetivo}
                        personajePiso={personajePiso}
                        manager={manager}
                        dispositivo={dispositivo}
                        touchJump={touchJump}
                        personajeRampa={personajeRampa}
                        personajePisoGeneral={personajePisoGeneral}
                        skateGirar={skateGirar}
                        funcionActivarTimer={funcionActivarTimer}
                        gano={gano}
                        refAudioSkateRunning={refAudioSkateRunning}
                        mostrarPersonaje={mostrarPersonaje}
                        funcionActualizarPiso={funcionActualizarPiso}
                        personajePisoFinal={personajePisoFinal}
                        restartGame={funcionActivarBug}
                      />
                    )}
                  </Physics>
                </Suspense>
              )}
              {/* <DOMObject dom={ref} />
               */}
            </Canvas>
            {/* <div
              style={{
                position: "fixed",
                bottom: "10px",
                left: "30px",
                zIndex: "9999",
                width: "30px",
                height: "30px",
                background: "red",
              }}
              onClick={() => {
                window.dispatchEvent(
                  new KeyboardEvent("keydown", { key: "w" })
                );
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                bottom: "10px",
                left: "70px",
                zIndex: "9999",
                width: "30px",
                height: "30px",
                background: "yellow",
              }}
              onClick={() => {
                window.dispatchEvent(
                  new KeyboardEvent("keydown", { key: "a" })
                );
              }}
            ></div> */}

            {isMobile && ocultarDiv && (
              <>
                <div
                  id="zone_joystick"
                  ref={refJoystick}
                  style={{
                    zIndex: "60",
                    position: "absolute",
                    left: "140px",
                    bottom: "85px",
                  }}
                ></div>

                <div
                  style={{
                    position: "absolute",
                    right: "100px",
                    bottom: "45px",
                    background: "#FFFFFF",
                    // background: "red",
                    opacity: touchJump ? "1" : "0.5",
                    zIndex: "60",
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                  onPointerDown={(e) => {
                    // window.dispatchEvent(
                    //   new KeyboardEvent("keydown", { key: " " })
                    // );
                    e.preventDefault();
                    e.stopPropagation();
                    // setInterval(() => {
                    setTouchJump(true);
                    // }, 0);
                  }}
                  onPointerLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // setTimeout(() => {
                    setTouchJump(false);
                    // }, 1500);
                  }}
                  // onClick={() => {
                  //   setTouchJump(true);
                  // }}
                ></div>
              </>
            )}

            <StopWatch
              activarTimer={activarTimer}
              sendTiempo={sendTiempo}
              stopTimer={stopTimer}
            />
            <div
              className="d-flex justify-content-end"
              style={{
                position: "absolute",
                zIndex: "60",
                top: "30px",
                right: "80px",
              }}
            >
              <div
                style={{
                  background: "rgba(255,255,255,0.5)",
                  borderRadius: "20px",
                  padding: "10px",
                }}
                className="d-flex justify-content-center"
              >
                <img
                  src={imagenCoin}
                  style={{ width: "40px", height: "auto" }}
                  alt="coins"
                />
                <div
                  className="d-flex align-items-center"
                  style={{ height: "40px", marginLeft: "5px" }}
                >
                  <span className="text-dark">
                    <b>
                      {cantidadCoins}/{coinsParaGanar}
                    </b>
                  </span>
                </div>
              </div>
            </div>
            {gano && (
              // <div>
              <Confetti
                width={width}
                height={height}
                style={{
                  zIndex: "3000",
                  width: width,
                  height: height,
                  // position: "absolute",
                }}
              />
              // </div>
            )}
          </div>
        </div>
      </>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Credits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Models from:</p>
          <p>
            - Skateboard by Poly by Google [CC-BY]
            (https://creativecommons.org/licenses/by/3.0/) via Poly Pizza
            (https://poly.pizza/m/cms3_9IcYbB)
          </p>
          <p> - Coin by Quaternius (https://poly.pizza/m/QHZtj94fvh)</p>
          <p> - https://market.pmnd.rs/</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
