import * as THREE from "three";
import {
  RigidBody,
  CuboidCollider,
  useRevoluteJoint,
  usePrismaticJoint,
  CapsuleCollider,
  RoundConvexPolygon,
  BallCollider,
} from "@react-three/rapier";
import { useFrame, extend, useLoader, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { useAnimations, useGLTF } from "@react-three/drei";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import gsap from "gsap";
import useSound from "use-sound";
import archivoSoindAndar from "./../sonidos/skate/skaterunning2.mp3";
import useAudio from "../sounds/useAudio";
import useSonido from "./useSonido";

// import { useControls } from "leva";
const Personaje = ({
  controls,
  sendObjetivo,
  personajePiso,
  manager,
  dispositivo,
  touchJump,
  personajeRampa,
  personajePisoGeneral,
  tipoRampa,
  skateGirar,
  funcionActivarTimer,
  gano,
  refAudioSkateRunning,
  mostrarPersonaje,
  funcionActualizarPiso,
  personajePisoFinal,
  resetearPersonaje,
  restartGame,
}) => {
  const { camera } = useThree();
  const [cubo, setCubo] = useState(null);
  const [estadoRotateSkate, setEstadoRotateSkate] = useState([0, 0, 0]);
  const [positionCubo, setPositionCubo] = useState([0, 1.5, 0]);
  const [rotationCubo, setRotationCubo] = useState();
  const [rotateSkate, setRotateSkate] = useState(false);
  const [cambioEstadoPiso, setCambioEstadoPiso] = useState(false);
  const refCollider = useRef(null);
  const [estado, setEstado] = useState(false);
  const [gravedad, setGravedad] = useState(0);
  const [cambioEstadoRampa, setCambioEstadoRampa] = useState(false);
  const [empezarTimer, setEmpezarTimer] = useState(false);
  const [moveForward, setMoveForward] = useState(false);
  const [listenerAndarSkate] = useState(() => new THREE.AudioListener());
  const [soundAndarSkate] = useState(() => new THREE.Audio(listenerAndarSkate));
  const [activarSonidoRun, setActivarSonidoRun] = useState(false);
  const [positionPersonaje, setPositionPersonaje] = useState([
    -29.3, 0.1, 90.2,
  ]);

  // create a global audio source
  // const buffer = useLoader(
  //   THREE.AudioLoader,
  //   "./sonidos/skate/skaterunning2.mp3"
  // );
  useEffect(() => {
    /**
     *
     * Sonido andar en skate
     *
     */
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load("./sonidos/skate/skaterunning2.mp3", function (buffer) {
      camera.add(listenerAndarSkate);
      soundAndarSkate.setBuffer(buffer);
      soundAndarSkate.setLoop(true);
      soundAndarSkate.setVolume(0.5);
    });
  }, []);
  //   audio: audioSkate,
  //   isRunning: isRunningSkate,
  //   audioCtx: audioCtxSkate,
  // } = useSonido({
  //   refAudio: refAudioSkateRunning,
  // });

  // const [
  //   playSoundAndar,
  //   { stop: stopSoundAndar, sound: soundAndar, pause: pauseAndar },
  // ] = useSound(archivoSoindAndar, {
  //   volume: 0.4,
  //   loop: true,
  //   interrupt: false,
  //   preload: true,
  // });

  // useEffect(() => {
  //   if (isRunningSkate) {
  //     console.log("playing");
  //   } else {
  //     console.log("pausing");
  //   }
  // }, [isRunningSkate]);
  const [isPlaying, setIsPlaying] = useState(false);
  /**
   *
   * Empiezan ref
   *
   *
   */
  const refMoveForward = useRef(false);
  // const [refMoveForward] = useHookWithRefCallback(false);
  const refMoveLeft = useRef(false);
  const refMoveRight = useRef(false);
  const refPersonaje = useRef(null);
  const refCubo = useRef(null);
  const refGoal = useRef(new THREE.Object3D());
  const refFollow = useRef(new THREE.Object3D());
  let worldVelocity = new THREE.Vector3();
  let localVelocity = new THREE.Vector3();
  const refLocalVelocity = useRef(localVelocity);
  const refWorldVelocity = useRef(worldVelocity);
  const refRotateSkate = useRef(false);
  const refSkate = useRef(null);
  const refCanJump = useRef(false);
  const refJump = useRef(false);
  let rotationQuaternion = new THREE.Quaternion();
  const refValorAxisY = useRef(0.17);
  const refEstabaJump = useRef(false);
  const dummy = new THREE.Vector3();
  const lookAtPos = new THREE.Vector3();
  var a = new THREE.Vector3();
  var b = new THREE.Vector3();
  var temp = new THREE.Vector3();
  var dir = new THREE.Vector3();
  var coronaSafetyDistance = 0.3;
  const jumpVelocity = 10;
  const refVelocidadJump = useRef(4);
  const refVelocidadJumpForward = useRef(3);
  const refPosicionTemporal = useRef(0);
  const refEstabaForward = useRef(false);
  const refPersonajePiso = useRef(personajePiso);
  const refModelSkate = useRef(null);
  const refMoveBase = useRef(450);
  const refBall = useRef(null);
  const refEstadoForward = useRef(false);
  const refPersonajeRampa = useRef(null);
  const refInterval = useRef(null);
  const refTimeOutRampa = useRef(null);
  const refTimeOutRampa2 = useRef(null);
  // const refPersonajePisoGeneral = useRef(personajePisoGeneral);
  // const refMoveBase = useRef(100);
  const refMoveDistance = useRef(refMoveBase.current * 1);
  // camera.lookAt(3, 0, 0);
  const refCameraZ = useRef(4);
  const refPersonajePisoGeneral = useRef(false);
  const refPersonajePisoFinal = useRef(false);
  const refContadorW = useRef(0);
  const refGano = useRef(false);
  const refAnimacionPateada = useRef(null);
  const refAnimacionIdle = useRef(null);
  const refLoopIdle = useRef(null);
  const refLoopPateada = useRef(null);
  const refGlbSkatePateada = useRef(null);
  const refModelo = useRef(null);
  const refIntervalBaranda = useRef(null);
  const refEstabaForward2 = useRef(false);
  const refSensor = useRef(null);
  const refSensor2 = useRef(null);
  const refPersonajeEstaEnRampa = useRef(null);
  const refResetearPersonaje = useRef(false);
  const refMostrarPersonaje = useRef(false);
  const refForwardSonido = useRef(false);
  /**
   *
   * Aca terminan las ref
   *
   *
   *
   */
  refModelo.current = useLoader(
    GLTFLoader,
    "./personaje/male-glb.glb",
    (loader) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("./draco/");
      loader.setDRACOLoader(dracoLoader);
      // console.log(modelo);
    }
  );
  // refModelo.current = useGLTF("../personaje/male-glb.glb");
  // const animations = useAnimations(
  //   refModelo.current.animations,
  //   refModelo.current.scene
  // );
  // useEffect(() => {
  //   // const action = animations.actions.Run
  //   // const action = animations.actions["Root.003|mixamo.com|Layer0"];
  //   // action.play();
  //   // animations.actions["Root.003|mixamo.com|Layer0.001"].play();
  //   refLoopIdle.current = new THREE.AnimationMixer(refModelo.current.scene);
  //   refAnimacionIdle.current = refLoopIdle.current.clipAction(
  //     refModelo.current.animations[0]
  //   );
  //   refAnimacionIdle.current.play();
  // }, []);
  const skate = useLoader(GLTFLoader, "./skate/skate.gltf", (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("./draco/");
    loader.setDRACOLoader(dracoLoader);
    // console.log(modelo);
  });
  const fbxSkateAnimation = useLoader(
    FBXLoader,
    "/skate/animaciones-fbx/pateada10.fbx"
  );
  const glbSkateAnimationPateada = useLoader(
    GLTFLoader,
    "./skate/animaciones-glb/pateada3-glb.glb"
  );
  const glbSkateAnimationIdle = useLoader(
    GLTFLoader,
    "./skate/animaciones-glb/idle-skate-glb.glb"
  );
  const glbSkateAnimationKeeper = useLoader(
    GLTFLoader,
    "./skate/animaciones-glb/goolkeeper-glb.glb"
  );

  // const animacioneSkate = useGLTF("./skate/animaciones-glb/varias1.glb");
  /**
   *
   * Animacion Idle
   */
  // refGlbSkatePateada.current = useLoader(
  //   GLTFLoader,
  //   "./skate/animaciones/skateboarding.glb"
  // );
  // const { animations } = useGLTF("./skate/animaciones/skateboarding.glb", true);
  // console.log("glb animation", glbSkateAnimationIdle.animations);

  // const animation1 = { ...glbSkateAnimationIdle.animations[0], jsonAnimation };

  // const animation2 = new THREE.AnimationClip(
  //   "pateadaprueba",
  //   jsonAnimation.duration
  // );
  // console.log("animation2", animation2)
  // glbSkateAnimationIdle.animations[0].tracks = jsonAnimation.tracks;
  const { actions: actionsPateada } = useAnimations(
    glbSkateAnimationPateada.animations,
    // [animation2],
    refModelo.current.scene
  );
  const { actions: actionsIdle } = useAnimations(
    glbSkateAnimationIdle.animations,
    // [animation2],
    refModelo.current.scene
  );
  const { actions: actionsKeeper } = useAnimations(
    glbSkateAnimationKeeper.animations,
    // [animation2],
    refModelo.current.scene
  );

  // const { animationName } = useControls({
  //   animationName: { options: ["idle", "pateada"] },
  // });
  // console.log("actions", actions);
  const refEnviado1 = useRef(false);
  // useEffect(() => {
  //     // const idleLoop = new THREE.AnimationMixer(modelo.scene);
  // const animacionSkate = idleLoop.clipAction(
  //   fbxSkateAnimation.animations[0]
  // );
  // animacionSkate.play();

  // const tick = () => {
  //   if (idleLoop) {
  //     idleLoop.update(0.016);
  //   }
  //   window.requestAnimationFrame(tick);
  // };
  // tick();
  // if (!actionsIdle) return;
  // if (refEnviado1.current) return;
  // refEnviado1.current = true;
  // const fileData = JSON.stringify(
  //   glbSkateAnimationIdle.animations[0].tracks.toString()
  // );
  // Find your clip named "SomeClipName"
  // const origClip = THREE.AnimationClip.findByName(
  //   glbSkateAnimationIdle.animations,
  //   "Root.003|mixamo.com|Layer0"
  // );
  // console.log("origClip", origClip);

  // Convert to JSON object, stringify, and parse
  // const clonedClipJSON = JSON.parse(JSON.stringify(origClip));
  // OR, this also works although it is not necessary:
  // const clonedClipJSON = JSON.parse(
  //   JSON.stringify(THREE.AnimationClip.toJSON(origClip))
  // );
  // console.log("clonedClipJSON", clonedClipJSON);

  // Parse with optional changes applied, in my case I had a method "applySomeChange"
  // const clipWithoutPosition = THREE.AnimationClip.parse(
  //   applySomeChange(clonedClipJSON)
  // );

  // Bonus
  // I'm personally using React-Three-Fiber and drei
  // See "useAnimations": https://github.com/pmndrs/drei#useanimations
  // const { actions } = useAnimations([ clipWithoutPosition ], group);
  // actions["SomeClipName"].play();
  // var jsonObject = glbSkateAnimationIdle.animations[0].toJSON(); // creates a primitive object with basic animation data
  // var fileData = JSON.stringify(jsonObject);
  // const blob = new Blob([fileData], { type: "text/plain" });
  // const url = URL.createObjectURL(blob);
  // const link = document.createElement("a");
  // link.download = "user-info.json";
  // link.href = url;
  // link.click();
  // refLoopPateada.current = new THREE.AnimationMixer(refModelo.current.scene);
  // refAnimacionPateada.current = refLoopPateada.current.clipAction(
  //   fbxSkateAnimation.animations[0]
  // );
  // refAnimacionPateada.current.play();
  // actionsPateada["pateada"].play();
  // actionsIdle["idle"].play();
  // const anim2 = actions.get();
  // console.log("anim2", anim2);
  // }, [actionsIdle]);
  // useEffect(() => {
  // refLoopIdle.current = new THREE.AnimationMixer(modelo.scene);
  // refAnimacionIdle.current = refLoopIdle.current.clipAction(
  //   glbSkateAnimationIdle.animations[0]
  // );
  // console.log("glbSkateAnimationPateada", refGlbSkatePateada.current);
  // console.log("modelo", modelo.scene);
  // refLoopPateada.current = new THREE.AnimationMixer(modelo.scene);
  // refAnimacionPateada.current = refLoopPateada.current.clipAction(
  //   refGlbSkatePateada.current.animations[0]
  // );
  // console.log("refLoopPateada.current", refLoopPateada.current);
  // console.log("refAnimacionPateada.current", refAnimacionPateada.current);
  // refAnimacionPateada.current.play();
  // }, []);
  useFrame((state, deltaTime) => {
    // if (refResetearPersonaje.current) return;
    const position = refPersonaje.current.translation();
    const rotation = refPersonaje.current.rotation();

    if (position.y < 0.6 && mostrarPersonaje) {
      restartGame();
    }
    if (!cubo) return;
    // refBall.current.position(lerp(refBall.current.position(), position, 0.15));
    // refBall.current.setTranslation(position.x, position.y, position.z);
    // refBall.current.setTranslation(
    //   { x: position.x, y: position.y, z: position.z },
    //   true
    // );

    const axisY = new THREE.Vector3(0, refValorAxisY.current, 0);
    // cubo.position.copy(position);
    cubo.quaternion.copy(rotation);

    cubo.position.lerp(position, 0.26);
    refModelSkate.current.position.lerp(position, 0.26);
    // refModelSkate.current.position.copy(position);
    refModelSkate.current.quaternion.copy(rotation);

    // refSensor2.current.position(position.x, position.y + 0.5, position.z);
    // refSensor.current.setTranslation(
    //   { x: position.x, y: position.y + 0.5, z: position.z },
    //   true
    // );
    let rotateAngle = Math.PI * 2 * deltaTime;
    if (refLoopIdle.current) {
      refLoopIdle.current.update(0.016);
    }
    if (refLoopPateada.current) {
      // console.log("entra en pateda");
      refLoopPateada.current.update(0.016);
    }
    if (refMoveLeft.current) {
      //Quaternion
      rotationQuaternion.setFromAxisAngle(axisY, rotateAngle);
      let temporal1 = rotationQuaternion.multiply(rotation);

      refPersonaje.current.setRotation(
        {
          w: temporal1.w,
          x: temporal1.x,
          y: temporal1.y,
          z: temporal1.z,
        },
        true
      );
    } else if (refMoveRight.current) {
      rotationQuaternion.setFromAxisAngle(axisY, -rotateAngle);

      // let temporal3 = new THREE.Quaternion(
      //   rotation.x,
      //   rotation.y,
      //   rotation.z,
      //   rotation.w
      // );
      let temporal1 = rotationQuaternion.multiply(rotation);

      refPersonaje.current.setRotation(
        {
          w: temporal1.w,
          x: temporal1.x,
          y: temporal1.y,
          z: temporal1.z,
        },
        true
      );
    }
    const zoom = 1;
    const step = 0.1;
    // state.camera.fov = THREE.MathUtils.lerp(
    //   state.camera.fov,
    //   zoom ? 10 : 42,
    //   step
    // );
    // state.camera.position.lerp(
    //   dummy.set(zoom ? 25 : 10, zoom ? 1 : 5, zoom ? 0 : 10),
    //   step
    // );

    // lookAtPos.x = Math.sin(state.clock.getElapsedTime() * 2);
    if (!cubo) return;
    // state.control.target(refCubo.current.position);
    // console.log("controls", state);
    // refGoal.current.add(state.camera);
    // camera.lookAt(refCubo.current.position);
    // camera.updateProjectionMatrix();

    // a.lerp(refCubo.current.position, 0.4);
    // b.copy(refGoal.current.position);

    // dir.copy(a).sub(b).normalize();
    // const dis = a.distanceTo(b) - coronaSafetyDistance;
    // refGoal.current.position.addScaledVector(dir, dis);
    // //Aca esta el movimiento
    // refGoal.current.position.lerp(temp, 0.05);
    // temp.setFromMatrixPosition(refFollow.current.matrixWorld);
    /**
     *
     *
     * Segundo intento funciona bien
     */
    // camera.lookAt(refCubo.current.position);
    let z, y;
    if (refMoveForward.current) {
      z = refCameraZ.current;
      y = 3;
    } else {
      z = refCameraZ.current;
      y = 3;
      // camera.lookAt(
      //   refCubo.current.position
      // );
    }

    if (!refGano.current) {
      const relativeCameraOffset = new THREE.Vector3(0, y, z);
      // relativeCameraOffset = new THREE.Vector3(0, 4, 20);
      // console.log("ref cubo es", refCubo.current);
      const cameraOffset = relativeCameraOffset.applyMatrix4(
        refCubo.current.matrixWorld
      );
      camera.lookAt(refCubo.current.position);

      camera.updateProjectionMatrix();

      camera.position.lerp(
        new THREE.Vector3(cameraOffset.x, cameraOffset.y, cameraOffset.z),
        // 0.036
        0.125
        // 0.15
      );
    }
    // let moveDistance = 180 * 0.016;
    let moveDistance = refMoveDistance.current * 0.016;

    refLocalVelocity.current.set(0, 0, moveDistance * 4);

    refWorldVelocity.current = refLocalVelocity.current;
    refWorldVelocity.current.applyQuaternion(rotation);
    // const impulse = { x: 0, y: 0, z: 0 };
    // const torque = { x: 0, y: 0, z: 0 };
    const linvel = refPersonaje.current.linvel();
    // console.log("position y", position.y);
    if (refJump.current) {
      // refEstabaJump.current = true;
      // refPosicionTemporal.current = position.y + 1.5;
      // refPersonaje.current.applyImpulse({ x: 0, y: 100, z: 0 }, true);
    }
    // console.log("refPersonajePiso.current", refPersonajePiso.current);
    // console.log("refEstabaJump.current", refEstabaJump.current);

    if (!refPersonajePisoGeneral.current && refEstabaJump.current) {
      // console.log("entra aca 1");
      if (position.y >= refPosicionTemporal.current) {
        // console.log("entra aca 2");
        // refPersonaje.current.applyImpulse({ x: 0, y: -300, z: 0 }, true);
        refPersonaje.current.setLinvel(
          {
            x: linvel.x,
            y: -1,
            z: linvel.z,
          },
          true
        );
        refEstabaJump.current = false;
        refEstadoForward.current = false;
      }
    }
    // 2 ,  1
    // if (refPosicionTemporal.current > position.y) {
    //   // refCanJump.current = false;
    //   console.log("entro aca2");
    //   refVelocidadJump.current = -3;
    // } else {
    //   refPosicionTemporal.current = position.y;
    // }

    // if (refCanJump.current && position.y > 3) {
    //   console.log("entro aca");
    //   refCanJump.current = false;
    //   refVelocidadJump.current = -3;
    // }
    // if (refCanJump.current) {
    // refVelocidadJump.current -= 0.1;
    // }
    // if (refJump.current && refMoveForward.current) {
    //   refPersonaje.current.setLinvel(
    //     {
    //       x: -refWorldVelocity.current.x,
    //       // y: refJump.current ? 0 : -3,
    //       y: refVelocidadJump.current,
    //       z: -refWorldVelocity.current.z,
    //     },
    //     true
    //   );
    // } else
    // console.log("linvel y", linvel.y);
    // if (refJump.current && !refMoveForward.current) {
    //   refPersonaje.current.setLinvel(
    //     {
    //       x: linvel.x,
    //       y: refVelocidadJump.current,
    //       z: linvel.z,
    //     },
    //     true
    //   );
    // }
    // if (refJump.current && refEstabaForward.current) {
    //   console.log("entra aca 3");
    // refPersonaje.current.setLinvel(
    //   {
    //     x: linvel.x,
    //     y: refVelocidadJumpForward.current,
    //     z: linvel.z,
    //   },
    //   true
    // );

    // if (refJump.current) {
    //   refPersonaje.current.setLinvel(
    //     {
    //       x: linvel.x,
    //       y: 6,
    //       z: linvel.z,
    //     },
    //     true
    //   );
    //   setTimeout(() => {
    //     refPersonaje.current.setLinvel(
    //       {
    //         x: linvel.x,
    //         y: 0,
    //         z: linvel.z,
    //       },
    //       true
    //     );
    //   }, 200);
    // }
    // refPersonaje.current.applyImpulse({ x: 0, y: 0.0001, z: 0 });
    // }

    // console.log("refPersonaje.current", refPersonaje.current.linvel());
    // } else
    // console.log("refVelocidadJump.current", refVelocidadJump.current);
    // if (refMoveForward.current && refCanJump.current) {
    //   refPersonaje.current.setLinvel(
    //     {
    //       x: -refWorldVelocity.current.x,
    //       // y: refJump.current ? refVelocidadJump.current : -3,
    //       y: refVelocidadJump.current,
    //       z: -refWorldVelocity.current.z,
    //     },
    //     true
    //   );
    // } else if (refMoveForward.current && !refCanJump.current) {
    //   refPersonaje.current.setLinvel(
    //     {
    //       x: -refWorldVelocity.current.x,
    //       // y: refJump.current ? refVelocidadJump.current : -3,
    //       y: -3,
    //       z: -refWorldVelocity.current.z,
    //     },
    //     true
    //   );
    // }
    // refPersonaje.current.applyImpulse(impulse, true);
    // console.log("refPersonajePiso.current", refPersonajePiso.current);

    // if (!refPersonajePiso.current) {
    //   refPersonaje.current.setLinvel(
    //     {
    //       x: linvel.x,
    //       y: 0,
    //       z: linvel.z,
    //     },
    //     true
    //   );
    // }

    if (
      refMoveForward.current &&
      !refPersonajeRampa.current &&
      (refPersonajePisoFinal.current || refPersonajePiso.current)
    ) {
      refForwardSonido.current = true;
      refContadorW.current++;
      // console.log("refContadorW.current", refContadorW.current);
      // console.log("entra aca 4");
      refPersonaje.current.setLinvel(
        {
          x: -refWorldVelocity.current.x,
          y: -3,
          z: -refWorldVelocity.current.z,
        },
        true
      );
      // refBall.current.setLinvel(
      //   {
      //     x: -refWorldVelocity.current.x,
      //     y: -3,
      //     z: -refWorldVelocity.current.z,
      //   },
      //   true
      // );
    } else {
      refForwardSonido.current = false;
    }
  });

  useEffect(() => {
    const keyDown = (e) => {
      if (!refMostrarPersonaje.current) return;
      if (refGano.current) return;
      const key = e.key.toLowerCase();
      // console.log(key);
      refContadorW.current = 0;
      if (key === " ") {
        // console.log("salta1");
        // refPersonaje.current.applyImpulse({ x: 0, y: 100, z: 0 }, true);
        // if (refMoveForward.current) {
        //   refMoveForward.current = false;
        //   refMoveForward.current = true;
        //   // window.dispatchEvent(new KeyboardEvent("keydown", { key: "w" }));
        // }
        jump();
        // refPersonaje.current.applyImpulse({ x: 0, y: 850, z: 0 }, true);
        // setCambioEstadoPiso(!cambioEstadoPiso);
        // refJumpValor.current = 10;
        // refPersonaje.current.setLinvel({ x: 0, y: 10, z: 0 }, true);
      }
      if (key === "w") {
        // if (!refPersonajePiso.current) return;
        if (!moveForward) {
          setMoveForward(true);
        }
        funcionCambioEstadoRampa();
        refMoveForward.current = true;
        refEstabaForward2.current = true;
        if (empezarTimer) return;
        setEmpezarTimer(true);
        funcionActivarTimer(true);
        // if (refPersonajePiso.current) {
        // console.log("persona piso en tecla w", refPersonajePiso.current);
        // refContadorW.current += 1;
        // console.log("se ejecuto tecla w");
        // }
      }
      if (key === "a") {
        refMoveLeft.current = true;
      } else if (key === "d") {
        refMoveRight.current = true;
      }
      if (key === "r") {
        refRotateSkate.current = true;
        // console.log("refPersonaje", refPersonaje.current);
        // refSkate.current.rotation.y = Math.PI * 0.5;
        // setEstadoRotateSkate([0, Math.PI * 0.5, 0]);
        // setRotateSkate(true);
      }
      if (key === "f") {
        refRotateSkate.current = true;
        // console.log("refPersonaje", refPersonaje.current);
        // refSkate.current.rotation.y = Math.PI * 0.5;
        // setEstadoRotateSkate([0, 0, 0]);
        // setRotateSkate(false);
      }
    };
    const keyUp = (e) => {
      const key = e.key.toLowerCase();
      // console.log(key);
      if (key === " ") {
        refJump.current = false;
      }
      if (key === "w") {
        refMoveForward.current = false;
        refEstabaForward.current = false;
        refEstabaForward2.current = false;
        setMoveForward(false);
      }
      if (key === "a") {
        refMoveLeft.current = false;
      } else if (key === "d") {
        refMoveRight.current = false;
      }
      if (key === "r") {
        refRotateSkate.current = false;
      }
    };
    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);
    return () => {
      window.removeEventListener("keydown", keyDown);
      window.removeEventListener("keyup", keyUp);
    };
    // refPersonaje.current.lockRotations();
    // refFollow.current = new THREE.Object3D();
    // refFollow.current.position.z = -coronaSafetyDistance;
  }, []);
  useEffect(() => {
    if (!estado) return;
    // refCollider.current.setAdditionalMass(100);
    // refCollider.current.setGravityScale(100.0);
    refPersonaje.current.setDominanceGroup(10);
    refPersonaje.current.name = "personaje";
  }, [estado]);

  useEffect(() => {
    // console.log("persona piso en componente", personajePiso);
    refPersonajePiso.current = personajePiso;
    refPersonajePisoGeneral.current = personajePisoGeneral;
    refPersonajePisoFinal.current = personajePisoFinal;
    // refPersonajePiso.current = true;
    if (personajePiso) {
      //Esta en el piso
      refMoveDistance.current = 450;
      if (refEstabaForward.current) {
        // console.log("refEstabaForward.current", refEstabaForward.current);
        refMoveForward.current = true;
      }
      // clearInterval(refInterval.current);
    } else {
      //Esta en el aire
      // setGravedad(30);
      // refInterval.current = setInterval(() => {
      // refMoveForward.current = false;
      // }, 50);
      // for (let i = 0; i < 1000; i++) {
      //   refMoveForward.current = false;
      // }
      // refEstabaForward.current = false;
    }
  }, [
    personajePiso,
    cambioEstadoPiso,
    personajePisoGeneral,
    personajePisoFinal,
  ]);
  // useEffect(() => {
  // console.log("personajePisoGeneral", personajePisoGeneral);
  // console.log("personajeRampa", personajeRampa);
  // }, [personajeRampa, personajePisoGeneral]);
  // useEffect(() => {
  // (async () => {
  //   const idleLoop = new THREE.AnimationMixer(modelo.scene);
  //   const animacionSkate = idleLoop.clipAction(
  //     fbxSkateAnimation.animations[0]
  //   );
  //   animacionSkate.play();

  // const tick = () => {
  //   if (idleLoop) {
  //     idleLoop.update(0.016);
  //   }
  //   window.requestAnimationFrame(tick);
  // };
  // tick();
  // })();
  // }, []);
  useEffect(() => {
    refMostrarPersonaje.current = mostrarPersonaje;
    refCubo.current.visible = false;
    refModelSkate.current.visible = false;
    if (!mostrarPersonaje) return;
    window.dispatchEvent(new KeyboardEvent("keydown", { key: " " }));
    refCubo.current.visible = true;
    refModelSkate.current.visible = true;
    refPersonaje.current.setLinvel(
      {
        x: 0,
        y: 15,
        z: 0,
      },
      true
    );
  }, [mostrarPersonaje]);

  // useEffect(() => {
  //   let relativeCameraOffset;
  //   relativeCameraOffset = new THREE.Vector3(0, 1, 20);
  //   // relativeCameraOffset = new THREE.Vector3(0, 4, 20);
  //   // console.log("ref cubo es", refCubo.current);
  //   const cameraOffset = relativeCameraOffset.applyMatrix4(
  //     refCubo.current.matrixWorld
  //   );
  //   camera.lookAt(refCubo.current.position);
  //   camera.updateProjectionMatrix();

  //   camera.position.copy(
  //     new THREE.Vector3(cameraOffset.x, cameraOffset.y, cameraOffset.z)
  //   );
  // }, []);
  useFrame(() => {
    // let relativeCameraOffset;
    // relativeCameraOffset = new THREE.Vector3(0, 1, 20);
    // // relativeCameraOffset = new THREE.Vector3(0, 4, 20);
    // // console.log("ref cubo es", refCubo.current);
    // const cameraOffset = relativeCameraOffset.applyMatrix4(
    //   refCubo.current.matrixWorld
    // );
    // camera.lookAt(refCubo.current.position);
    // camera.updateProjectionMatrix();
    // camera.position.copy(
    //   new THREE.Vector3(cameraOffset.x, cameraOffset.y, cameraOffset.z)
    // );
  });
  useEffect(() => {
    if (!manager) return;
    manager["0"].on("move", function (evt, data) {
      if (refGano.current) return;
      const forward = data.vector.y;
      const turn = data.vector.x;
      // console.log("turn", turn);
      if (forward > 0) {
        if (refEstabaJump.current) return;
        if (!moveForward) {
          setMoveForward(true);
        }
        funcionCambioEstadoRampa();
        refMoveForward.current = true;
        refEstabaForward2.current = true;
        if (empezarTimer) return;
        setEmpezarTimer(true);
        funcionActivarTimer(true);
        // console.log("refEstadoFoward", refEstadoForward.current);
        // if (refEstadoForward.current) return;

        // refMoveDistance.current = Math.abs(forward) * refMoveBase.current;

        // refMoveForward.current = true;
        // refEstabaForward2.current = true;
        // setMoveForward(true);
      } else if (forward < 0) {
        // fwdValue = 0
        // bkdValue = Math.abs(forward)
        // refMoveForward.current = false;
      }

      if (turn > 0 && turn <= 0.17) {
        refMoveRight.current = false;
      }

      if (turn < 0 && turn >= -0.17) {
        refMoveLeft.current = false;
      }

      if (turn > 0.17) {
        //derecha
        if (turn >= 0.17 && turn <= 0.7) {
          //Aca gira mas despacio
          refValorAxisY.current = Math.abs(turn) * 0.08;
          refMoveRight.current = true;
          refMoveLeft.current = false;
        } else {
          refValorAxisY.current = Math.abs(turn) * 0.17;
          refMoveRight.current = true;
          refMoveLeft.current = false;
        }

        // lftValue = 0;
        // rgtValue = Math.abs(turn);
      } else if (turn < -0.17) {
        if (turn >= 0.17 && turn <= 0.7) {
          //Aca gira mas despacio
          refValorAxisY.current = Math.abs(turn) * 0.08;
          refMoveLeft.current = true;
          refMoveRight.current = false;
        } else {
          refValorAxisY.current = Math.abs(turn) * 0.17;
          refMoveLeft.current = true;
          refMoveRight.current = false;
        }
        //izquierda

        // lftValue = Math.abs(turn);
        // rgtValue = 0;
      }
    });

    manager["0"].on("end", function (evt) {
      // refMoveDistance.current = 250;
      // refMoveForward.current = false;
      refMoveRight.current = false;
      refMoveLeft.current = false;
      refMoveForward.current = false;
      refEstabaForward.current = false;
      refEstabaForward2.current = false;
      setMoveForward(false);
      // bkdValue = 0
      // fwdValue = 0
      // lftValue = 0
      // rgtValue = 0
    });
  }, [manager]);

  // useEffect(() => {
  //   if (dispositivo === "mobile") {
  //     refCameraZ.current = 3;
  //   } else {
  //     refCameraZ.current = 3;
  //   }
  // }, [dispositivo]);
  useEffect(() => {
    if (isMobile) {
      refCameraZ.current = 4;
    }
  }, []);
  // useEffect(() => {
  //   console.log("refPersonaje.current", refPersonaje.current);
  // }, []);
  // const joint = useRevoluteJoint(refPersonaje, refCollider, [
  //   [0, 0, 0], // Position of the joint in bodyA's local space
  //   [0, 0, 0], // Position of the joint in bodyB's local space
  //   [0, 0, 0], // Axis of the joint, expressed in the local-space of the rigid-bodies it is attached to.
  // ]);
  // return (
  //   <group
  //   // ref={refCubo}
  //   // position={[0, 6, 0]}
  //   >
  //     <RigidBody ref={refPersonaje} colliders={"ball"}>
  //       {/* Generate mesh with sphere geometry */}
  //       <mesh>
  //         <sphereGeometry args={[1, 32, 32]} />
  //         <meshStandardMaterial color="red" />
  //       </mesh>
  //     </RigidBody>
  //     <RigidBody ref={refCollider}>{/* <mesh /> */}</RigidBody>
  //   </group>
  // );
  const jump = () => {
    // console.log("jump");
    if (refPersonajeEstaEnRampa.current) return;
    // console.log("entra en jump");
    // if (!refPersonajePisoGeneral.current) return;
    // if (!refPersonajePiso.current || !refPersonajePisoGeneral.current) return;l
    // console.log("refPersonajePisoFinal.current", refPersonajePisoFinal.current);
    if (!refPersonajePisoFinal.current) return;
    refMoveDistance.current = 50;
    // console.log("entro en jump");

    if (refMoveForward.current) {
      // console.log("entra en keydown");
      refEstabaForward.current = true;
      refMoveForward.current = false;
    }
    // refPosicionTemporal.current = 0;
    refVelocidadJump.current = 6;
    refCanJump.current = true;
    refJump.current = true;
    // console.log("111");
    // console.log("salta 2");
    refEstabaJump.current = true;
    // const position = refPersonaje.current.translation();
    // refPosicionTemporal.current = position.y + 2;
    // refPersonaje.current.applyImpulse({ x: 0, y: 2500, z: 0 }, true);
    setTimeout(() => {
      // console.log("entra en timeout");
      const position = refPersonaje.current.translation();
      refPosicionTemporal.current = position.y + 2.27;
      // refPersonaje.current.applyImpulse({ x: 0, y: 1000, z: 0 }, true);
      // console.log("refMoveForward.current", refEstabaForward.current);
      // if (refEstabaForward.current) {
      // console.log("entro en timeout");
      const linvel = refPersonaje.current.linvel();
      refPersonaje.current.setLinvel(
        { x: linvel.x, y: linvel.y + 8, z: linvel.z },
        true
      );
      refMoveDistance.current = 450;
      // refEstadoForward.current = false;
    }, 50);
    // }
  };
  useEffect(() => {
    if (touchJump) {
      // if (refMoveForward.current) {
      //   refEstabaForward.current = true;
      //   refMoveForward.current = false;
      // }
      // refVelocidadJump.current = 6;
      // refCanJump.current = true;
      // refJump.current = true;
      // refEstabaJump.current = true;
      // console.log("touchJump true");
      // jump();
      // window.dispatchEvent(new KeyboardEvent("keyup", { key: "w" }));
      // console.log("jump en personaje");
      // window.dispatchEvent(new KeyboardEvent("keydown", { key: " " }));
      // refEstadoForward.current = true;+
      jump();
    } else {
      // console.log("touchJump false");
      refJump.current = false;
      // refEstadoForward.current = false;
    }
  }, [touchJump]);

  useEffect(() => {
    // refPersonajeRampa.current = personajeRampa;
    // console.log("personajeRampa", personajeRampa);
    // console.log("cambioEstadoRampa", cambioEstadoRampa);
    // clearTimeout(refTimeOutRampa.current);
    // refTimeOutRampa.current = setTimeout(() => {
    //   if (personajeRampa && refMoveForward.current) {
    //     // console.log("entra en true");
    //     refPersonajeRampa.current = true;
    //   } else {
    //     // console.log("entra en false");
    //     refPersonajeRampa.current = false;
    //   }
    // }, 150);
    // refPersonajePisoGeneral.current = personajePisoGeneral;
    funcionTimeOutRampa();
    // console.log("refPersonajeRampa.current", refPersonajeRampa.current);
    // refPersonajeRampa.current = false;

    // if (personajeRampa) {
    //   refMoveForward.current = false;
    //   refEstabaForward.current = false;
    // }
    // console.log("refMoveForward.current", refMoveForward.current);
    // console.log("refPersonajePiso.current", refPersonajePiso.current);
    // console.log("refPersonajeRampa.current", refPersonajeRampa.current);
    /**
     *
     *
     * Aca hago las animaciones
     *
     *
     */
    refPersonajeEstaEnRampa.current = personajeRampa;
    if (personajeRampa) {
      refContadorW.current = 0;
    }
  }, [personajeRampa, cambioEstadoRampa, personajePisoGeneral]);

  const funcionTimeOutRampa = () => {
    clearTimeout(refTimeOutRampa2.current);
    // let position = refPersonaje.current.translation();
    refTimeOutRampa2.current = setTimeout(() => {
      // const position2 = refPersonaje.current.translation();
      // console.log("position", position);
      // console.log("position2", position2);
      // if (position === position2) {
      //   funcionTimeOutRampa();
      //   position = refPersonaje.current.translation();
      //   refPersonajeRampa.current = false;
      //   return;
      // }
      if (
        personajeRampa &&
        refMoveForward.current &&
        !refPersonajePiso.current
      ) {
        if (tipoRampa === "rampa2") {
          if (refContadorW.current < 5) {
            refPersonajeRampa.current = false;
            funcionTimeOutRampa();
            return;
          } else {
            // refPersonajeRampa.current = true;
          }
        } else if (tipoRampa === "rampa3") {
          if (refContadorW.current < 15) {
            // console.log("entro en contador w");
            refPersonajeRampa.current = false;
            funcionTimeOutRampa();
            return;
          } else {
            // console.log("entro en contador2 w");
            // refPersonajeRampa.current = true;
          }
        } else {
          // console.log("entra aca1111");
          refPersonajeRampa.current = true;
          // funcionTimeOutRampa();
          // return;
        }
        // console.log(" refContadorW.current", refContadorW.current);
        // console.log("entra en true");
        // console.log("refMoveForward.current", refMoveForward.current);
        // console.log("refPersonajePiso.current", refPersonajePiso.current);
        // console.log("refPersonajeRampa.current", refPersonajeRampa.current);
      } else {
        // console.log("entra en false");
        refPersonajeRampa.current = false;
        funcionTimeOutRampa();
      }
    }, 150);
  };

  const funcionCambioEstadoRampa = () => {
    // console.log("entra en funcionCambioEstadoRampa");
    // setCambioEstadoRampa(!cambioEstadoRampa);
  };

  useEffect(() => {
    if (skateGirar) {
      setEstadoRotateSkate([0, Math.PI * 0.5, 0]);
      setRotateSkate(true);
      if (actionsKeeper["keeper"].isRunning()) return;
      actionsKeeper["keeper"].clampWhenFinished = true;
      actionsKeeper["keeper"].fadeIn(0.1);
      actionsKeeper["keeper"].play();
      actionsKeeper["keeper"].fadeIn(0.1);
      actionsPateada["pateada"].stop();
      actionsIdle["idle"].stop();
      refIntervalBaranda.current = setInterval(() => {
        // window.dispatchEvent(new KeyboardEvent("keydown", { key: "w" }));
        refMoveForward.current = true;
      }, 100);
    } else {
      setEstadoRotateSkate([0, 0, 0]);
      setRotateSkate(false);
      if (refIntervalBaranda.current) {
        clearInterval(refIntervalBaranda.current);
        // window.dispatchEvent(new KeyboardEvent("keyup", { key: "w" }));
        if (refEstabaForward2.current) {
          refMoveForward.current = true;
        } else {
          refMoveForward.current = false;
          refEstabaForward.current = false;
        }
      }
    }
  }, [skateGirar]);

  useEffect(() => {
    if (gano) {
      if (!controls) return;
      refGano.current = true;
      //function gsap to camera
      // camera.lookAt(0, 10, 0.3);
      controls.target.set(0, 3, 0.05);
      // console.log("gl", gl);
      gsap.to(camera.position, {
        duration: 2,
        x: 0,
        y: 4,
        z: 3.7,
        ease: "power2.out",
        onStart: () => {
          // camera.rotation.z = Math.PI * 1;
        },
      });
    } else {
      refGano.current = false;
      // console.log("refGano.current", refGano.current);
    }
  }, [gano, controls]);
  useEffect(() => {
    if (personajePiso && moveForward) {
      if (actionsPateada["pateada"].isRunning()) return;
      actionsPateada["pateada"].clampWhenFinished = true;
      actionsPateada["pateada"].fadeIn(0.75);
      actionsPateada["pateada"].play();
      actionsPateada["pateada"].fadeIn(0.75);
      // actionsPateada["pateada"].crossFadeFrom(actionsIdle["idle"], 1);

      actionsIdle["idle"].stop();
      // actionsPateada["pateada"].reset().fadeIn(0.5).play();

      // refAnimacionIdle.current.stop();
    } else {
      // refAnimacionPateada.current.fadeOut(1);
    }
    if (personajeRampa) {
      if (actionsIdle["idle"].isRunning()) return;
      actionsIdle["idle"].clampWhenFinished = true;

      actionsIdle["idle"].fadeIn(0.75);
      actionsIdle["idle"].play();
      actionsIdle["idle"].fadeIn(0.75);

      actionsKeeper["keeper"].stop();
      actionsPateada["pateada"].stop();
    }
    if (!personajePisoGeneral) {
      if (actionsIdle["idle"].isRunning()) return;
      actionsIdle["idle"].clampWhenFinished = true;
      actionsIdle["idle"].fadeIn(0.75);
      actionsIdle["idle"].play();
      actionsIdle["idle"].fadeIn(0.75);

      actionsPateada["pateada"].stop();
      actionsKeeper["keeper"].stop();
      // actionsIdle["idle"].reset().fadeIn(0.5).play();
    }

    if (!moveForward && personajePiso) {
      if (actionsIdle["idle"].isRunning()) return;

      actionsIdle["idle"].clampWhenFinished = true;
      actionsIdle["idle"].fadeIn(0.75);
      actionsIdle["idle"].play();
      actionsIdle["idle"].fadeIn(0.75);
      actionsPateada["pateada"].stop();
      actionsKeeper["keeper"].stop();
    }
  }, [personajePiso, personajePisoGeneral, personajeRampa, moveForward]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(() => {
    // console.log("entra en audio");
    // (async () => {
    // await delay(300);
    // console.log("personajePisoGeneral", personajePisoGeneral);
    if (
      (moveForward || refForwardSonido.current) &&
      refMoveForward.current &&
      activarSonidoRun &&
      (personajePisoGeneral || personajeRampa || personajePisoFinal)

      // activarSonidoRun
    ) {
      if (soundAndarSkate.isPlaying) return;
      // playSoundAndar();
      // if (readyAndar) {
      // playSoundAndar.play();
      // }
      // soundAndar.fade(0, 1, 1000);
      // audioSkate.play();
      // audioCtxSkate.resume();
      // audioCtxSkate.resume().then(function () {
      //   console.log("resume");
      // });
      soundAndarSkate.play();
    } else {
      // console.log("stop sound");
      // stopSoundAndar();
      // await delay(300);
      // stopSoundAndar();
      setTimeout(() => {
        if (!refPersonajePisoGeneral.current || !refMoveForward.current) {
          // audioCtxSkate.suspend();
          // audioCtxSkate.suspend().then(function () {
          //   console.log("suspend");
          // });
          // audioSkate.pause();
          // audioSkate.currentTime = 0;
          soundAndarSkate.pause();
        }
      }, 200);
    }
    // })();
  }, [
    moveForward,
    personajePisoGeneral,
    personajeRampa,
    personajePisoFinal,
    activarSonidoRun,
  ]);

  // useEffect(() => {
  //   soundAndar?.on("play", () => setIsPlaying(true));
  //   soundAndar?.on("stop", () => setIsPlaying(false));
  //   soundAndar?.on("end", () => setIsPlaying(false));
  //   soundAndar?.on("pause", () => setIsPlaying(false));
  // }, [soundAndar]);
  // useEffect(() => {
  //   console.log("isPlaying", isPlaying);
  // }, [isPlaying]);
  useEffect(() => {
    if (!resetearPersonaje) return;
    refResetearPersonaje.current = true;
    // {[-29.3, 0.1, 90.2]}
    refMoveForward.current = false;

    const linvel = refPersonaje.current.linvel();
    setPositionPersonaje([-29.3, 0.1, 90.2]);
    // refPersonaje.current.setTranslation(
    //   {
    //     x: -29.3,
    //     y: 100,
    //     z: 90.2,
    //   },
    //   true
    // );
    setTimeout(() => {
      // refPersonaje.current.applyImpulse({ x: 0, y: 10, z: 0 }, true);
    }, 1000);
    refResetearPersonaje.current = false;
  }, [resetearPersonaje]);
  return (
    <>
      <group>
        {/* <RigidBody
          colliders={"ball"}
          // ref={refPersonaje}
          position={[-35, 1.5, 90.2]}
        >
          <mesh>
            <sphereGeometry args={[0.1, 16, 16]} />
            <meshStandardMaterial color="red" />
          </mesh>
        </RigidBody> */}
        {/* <RigidBody */}
        {/* // ref={refSensor}
        // sensor={true}
        // colliders={false}
        // position={[-29.3, 0.1, 90.2]}
        // type="fixed"
        // > */}

        {/* </RigidBody> */}
        <RigidBody
          position-y={1}
          type="dynamic"
          position={positionPersonaje}
          // position={[70, 0.1, 90.2]}
          // position={[70, 10, 48]}
          ref={(ref) => {
            refPersonaje.current = ref;
            // refBall.current = ref;
          }}
          // lockRotations={true}
          // colliders={false}
          // density={1}
          // angularDamping={0}
          // linearDamping={0}
          // enabledRotations={["x", "z"]}
          friction={4}
          // restitution={0.1}
          name="personaje"
          rotation={[0, -Math.PI * 0.5, 0]}
          // rotation={[0, 0, 0]}
          userData={{
            name: "personaje",
          }}
          // colliders={"ball"}
          colliders={"hull"}
          // colliders={false}
          // colliders={[
          //   {
          //     type: "box",
          //     size: [0.3, 0.5, 0.3],
          //     position: [0, 0.6, 0],
          //     rotation: [0, Math.PI, 0],
          //     name: "personaje2",
          //   },
          // ]}
          enabledRotations={["x", "z"]}
          // includeInvisible={true}
          // mass={1}
          // density={1}
          // gravityScale={-1}
          onCollisionEnter={(e) => {
            // console.log("onCollisionEnter", e);
            // funcionActualizarPiso({
            //   id: 1000,
            //   estado: true,
            //   tipo: "personaje",
            // });
          }}
          onCollisionExit={(e) => {
            // console.log("onCollisionExit", e);
            // funcionActualizarPiso({
            //   id: 1000,
            //   estado: false,
            //   tipo: "personaje",
            // });
          }}
        >
          <CuboidCollider
            args={[0.35, 0.25, 0.35]}
            position={[0, -0.45, 0]}
            // ref={refSensor}
            sensor={true}
            onIntersectionEnter={(e) => {
              // console.log("enter personaje", e);
              if (
                e.colliderObject.name === "piso" ||
                e.colliderObject.name === "rampa"
              ) {
                setActivarSonidoRun(true);
                funcionActualizarPiso({
                  id: 1000,
                  estado: true,
                  tipo: "personaje",
                });
              }
            }}
            onIntersectionExit={(e) => {
              // console.log("exit personaje");
              setActivarSonidoRun(false);

              funcionActualizarPiso({
                id: 1000,
                estado: false,
                tipo: "personaje",
              });
            }}
            // onCollisionEnter={(e) => {
            //   funcionActualizarPiso({
            //     id: 1000,
            //     estado: true,
            //     tipo: "personaje",
            //   });
            // }}
            // onCollisionExit={(e) => {
            //   funcionActualizarPiso({
            //     id: 1000,
            //     estado: false,
            //     tipo: "personaje",
            //   });
            // }}
            // visible={false}
            type="fixed"
          />
          <CuboidCollider
            args={[0.2, 0.5, 0.2]}
            position={[0, 0.6, 0]}
            ref={(ref) => {
              refCollider.current = ref;
              setEstado(true);
            }}
            rotation-y={Math.PI}
            name="personaje2"
            // visible={false}
          />
          {/* Personaje */}

          {/* Skate */}
          <CuboidCollider
            args={[0.2, 0.12, 0.5]}
            position={[0, -0.3, 0]}
            ref={refSkate}
            rotation={estadoRotateSkate}
            // visible={false}
          />

          <object3D>
            <mesh
              castShadow
              position={[0, -0.06, 0]}
              rotation={estadoRotateSkate}
            >
              <boxGeometry args={[0.4, 1.2, 0.7]} />

              <meshStandardMaterial transparent={true} opacity={0} />
            </mesh>
          </object3D>
        </RigidBody>
      </group>

      {/* <mesh
        castShadow
        ref={(ref) => {
          // sendObjetivo(ref);
          refCubo.current = ref;
          setCubo(ref);
        }}
        position={[0, 3, 0]}
        // position={positionCubo}
        // renderOrder={1}
        // rotation={rotationCubo}
        alphaTest={1}
        depthTest={false}
        onClick={() => {
          alert("hola");
        }}
      >
        <boxGeometry args={[3, 3, 3]} />
        <meshPhongMaterial
          color="black"
          // depthWrite={false}
          // polygonOffset={true}
          // polygonOffsetFactor={-1}
          // polygonOffsetUnits={-4}
          // depthWrite={false}
        />
      </mesh> */}
      <group
        ref={(ref) => {
          refCubo.current = ref;
          setCubo(ref);
        }}
      >
        <primitive
          object={refModelo.current.scene}
          scale={0.7}
          // rotation={[0, Math.PI * 1, 0]}
          rotation-y={Math.PI * 1}
          position-y={-0.34}
        />
      </group>
      {/* <PersonajeSolo
        ref={(ref) => {
          refCubo.current = ref;
          setCubo(ref);
        }}
      /> */}
      <group
        ref={(ref) => {
          refModelSkate.current = ref;
        }}
      >
        <primitive
          object={skate.scene}
          scale={0.2}
          // rotation={[0, Math.PI * 1, 0]}
          // rotation-y={Math.PI * 0.5}
          rotation-y={rotateSkate ? Math.PI * 0 : Math.PI * 0.5}
          // position-y={-0.55}
          position-y={-0.4}
        />
      </group>
    </>
  );
};
function useHookWithRefCallback(valor) {
  const ref = useRef(valor);
  const setRef = useCallback((node) => {
    // console.log("ref.current", ref.current);
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }

    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      // console.log("node", node);
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [setRef];
}
// useGLTF.preload("./skate/animaciones-glb/varias1.glb");
export default Personaje;
