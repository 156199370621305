import { useState } from "react";
import { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import Button1 from "../componentesLayout/Button1";

const CartelPrueba = ({ sendRef, sendCompletoNombre, gano, tiempo }) => {
  const [nombre, setNombre] = useState("");
  const refButton = useRef(null);
  const refDiv = useRef(null);
  const refEnviado = useRef(false);
  const refNombre = useRef("");
  useEffect(() => {
    sendRef(refDiv.current);
    if (!refButton.current || refEnviado.current) return;
    refEnviado.current = true;
    refButton.current.addEventListener("pointerdown", (e) => {
      if (refNombre.current.trim().length === 0) return;

      sendCompletoNombre(true, refNombre.current);
    });
  }, []);

  useEffect(() => {
    refNombre.current = nombre;
  }, [nombre]);
  return (
    <div
      ref={refDiv}
      className="div-cartel-1"
      style={{
        width: "6000px",
        height: "850px",
        background: "#A91EE4",
        // background: "#952bff",
        border: "10px solid #A91EE4",
        // background: "transparent",
        zIndex: "2",
        position: "absolute",
        boxSizing: "unset ",
        display: "none",
      }}
    >
      {/* <span style={{ margin: "0 auto" }}>Welcome!</span> */}
      {/* <p className="text-center" style={{ fontSize: "40px", margin: "0 auto" }}>
        Name:
      </p> */}
      <Form.Group className="mb-3 p-2" controlId="formBasicEmail">
        <Form.Label
          style={{ fontSize: "40px", marginLeft: "1rem", color: "#FFFD55" }}
        >
          Name:
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Type your name or nick name 😊"
          size="lg"
          value={nombre}
          onChange={(e) => {
            setNombre(e.target.value);
          }}
          autoComplete={"off"}
        />
      </Form.Group>
      {/* Antes de habilitar el boton comentar esta linea de skateshop */}
      {/* <p style={{ margin: "2px" }}>You can go to shop to get a skateboard</p> */}
      {/* Habilitar tambien el addEventListener del boton */}

      <Button1 ref2={refButton}>Save</Button1>
      <div
        className="d-flex justify-content-center align-items-center mt-1"
        style={{ height: "118px" }}
      >
        <div
          // className="mt-4"
          style={{
            background: "rgba(0,0,0,0.75)",
            borderRadius: "20px",
            padding: "1px 10px",
            display: "inline-block",
          }}
        >
          <p
            className="text-center "
            style={{ fontSize: "40px", color: "#FFFD55", margin: "auto" }}
          >
            <span>{("0" + Math.floor((tiempo / 60000) % 60)).slice(-2)}:</span>
            <span>{("0" + Math.floor((tiempo / 1000) % 60)).slice(-2)}.</span>
            <span>
              {("0" + ((tiempo / 10) % 100)).slice(-2).includes(".")
                ? ("0" + ((tiempo / 10) % 100)).slice(-2).replaceAll(".", "0")
                : ("0" + ((tiempo / 10) % 100)).slice(-2)}
            </span>
          </p>
        </div>
      </div>

      {/* <button
        ref={refButton}
        style={{ background: "blue", width: "500px", height: "500px" }}
      >
        Click me
      </button> */}
      {/* <p style={{ margin: "2px" }}>Bienvenido numero uno</p>s */}
    </div>
  );
};

export default CartelPrueba;
