/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
function CartelFlecha(props) {
  const { nodes, materials } = useGLTF("./carteles/cartel-flecha.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.signage_3d_01_d_LOD1.geometry}
        // material={materials["lambert1.023"]}
        material={
          new THREE.MeshBasicMaterial({
            color: "#952bff",
          })
        }
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
    </group>
  );
}

useGLTF.preload("./carteles/cartel-flecha.glb");

export default CartelFlecha;
