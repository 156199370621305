// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import { useFrame, extend, useLoader } from "@react-three/fiber";
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
// const Model = () => {
//   const modelo = useLoader(GLTFLoader, "./rampas/4/rampa3.glb", (loader) => {
//     const dracoLoader = new DRACOLoader();
//     dracoLoader.setDecoderPath("./draco/");
//     loader.setDRACOLoader(dracoLoader);
//     // console.log(modelo);
//   });
//   return <primitive object={modelo.scene} scale={4} position={[0, 0, 0]} />;
// };
// export default Model;

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const Model = (props) => {
  const { nodes, materials } = useGLTF("./rampas/4/rampa3.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={props.scale}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ramp09_1.geometry}
          material={materials.S2_Ramp09_1Mat}
        />
      </group>
    </group>
  );
};

useGLTF.preload("./rampas/4/rampa3.glb");

export default Model;
