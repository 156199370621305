import React, { useRef } from "react";
import { useGLTF, useMatcapTexture, useNormalTexture } from "@react-three/drei";
import * as THREE from "three";
import { useState } from "react";
const Baranda1 = (props) => {
  const [material, setMaterial] = useState();

  const [matcapTexture] = useMatcapTexture("5F1827_9B4A60_1F0404_340406", 64);

  const { nodes, materials } = useGLTF("./barandas/baranda1.glb");
  return (
    <group {...props} dispose={null}>
      <meshMatcapMaterial ref={setMaterial} matcap={matcapTexture} />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        // material={materials.Material}
        // material={
        //   new THREE.MeshMatcapMaterial({
        //     // map: colorTexture,
        //     color: "#a80000",
        //   })
        // }
        material={material}
        scale={[8, 0.2, 0.3]}
      />
    </group>
  );
};

useGLTF.preload("./barandas/baranda1.glb");

export default Baranda1;
