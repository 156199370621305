/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
function Coin1(props) {
  const refCoin = useRef(null);
  useFrame(() => {
    refCoin.current.rotation.y += 0.02;
  });
  const { nodes, materials } = useGLTF("./coins/coin.glb");
  return (
    <group {...props} dispose={null} ref={refCoin}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={200}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coin_1.geometry}
          //   material={materials.Yellow}
          material={new THREE.MeshStandardMaterial({ color: "#EDD320" })}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coin_2.geometry}
          //   material={materials.DarkYellow}
          material={new THREE.MeshPhongMaterial({ color: "#B8A318" })}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./coins/coin.glb");

export default Coin1;
