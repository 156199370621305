import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

function RampaBaranda(props) {
  const { nodes, materials } = useGLTF(
    "./rampas/rampa-baranda/rampa-baranda.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group
        //   position={[0, 0, 227.45]}
        position={props.position}
        rotation={props.rotation}
      >
        <group rotation={props.rotation}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Ramp02_1_S3_Ramp02_1Mat_0.geometry}
            material={materials.S3_Ramp02_1Mat}
            scale={props.scale}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./rampas/rampa-baranda/rampa-baranda.glb");

export default RampaBaranda;
