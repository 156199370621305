import { useFrame, useThree } from "@react-three/fiber";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { useRef, useState } from "react";
import Baranda1 from "../Barandas/baranda1";
import Coin1 from "../Coins/Coin";
import RampaBaranda from "../Rampas/RampaBaranda";
import { Gapless5 } from "@regosen/gapless-5";
import archivoSoundCoin from "./../sonidos/coin.mp3";
import CartelFlecha from "../Carteles/CartelFlecha";
import useSound from "use-sound";
import { useEffect } from "react";
import * as THREE from "three";
const GrupoRampas = ({
  funcionActualizarPiso,
  positionGroup,
  rotationGroup,
  contadorCoins,
}) => {
  const { camera } = useThree();
  const [coin1, setCoin1] = useState(true);
  const [coin2, setCoin2] = useState(true);
  // const [playSoundCoin, { stop: stopSoundCoin }] = useSound(archivoSoundCoin, {
  //   volume: 0.5,
  // });
  const [listenerCoin] = useState(() => new THREE.AudioListener());
  const [soundCoin] = useState(() => new THREE.Audio(listenerCoin));
  const refEstadoCoin1 = useRef(false);
  const refEstadoCoin2 = useRef(false);

  useEffect(() => {
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load("./sonidos/coin.mp3", function (buffer) {
      camera.add(listenerCoin);
      soundCoin.setBuffer(buffer);
      // soundCoin.setLoop(true);
      soundCoin.setVolume(0.6);
    });
  }, []);
  return (
    <group position={positionGroup} rotation={rotationGroup}>
      {/* <RigidBody
        type="fixed"
        // position-y={0.47}
        // position={[-0.5, 1.5, 90]}
        // rotation={[-Math.PI / 2, 0, 0]}
        colliders={false}
        onCollisionEnter={(event) => {
          if (event.rigidBody.userData.name === "personaje") {
            funcionActualizarPiso({ id: 2, estado: true });
          }
        }}
        onCollisionExit={(event) => {
          // console.log("collision exit3", event);
          if (event.rigidBody.userData.name === "personaje") {
            funcionActualizarPiso({ id: 2, estado: false });
          }
        }}
        friction={0}
      >
        <CuboidCollider
          args={[8, 0.6, 0.4]}
          position={[0, -0.4, 0]}
          rotation-y={Math.PI}
          name="personaje2"
          // visible={false}
        />
        <Baranda1 />
      </RigidBody> */}
      <group>
        <RigidBody
          name="rampa"
          type="fixed"
          colliders={"hull"}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 101, estado: true, tipo: "rampa" });
            }
          }}
          onCollisionExit={(event) => {
            // console.log("collision exit3", event);
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 101, estado: false, tipo: "rampa" });
            }
          }}
          friction={-2.55}
        >
          {/* <instancedMesh> */}
          <RampaBaranda
            position={[-7, -1.5, 1]}
            scale={[0.1, 0.13, 0.13]}
            rotation={[Math.PI * 2, Math.PI * 1.5, 0]}
          />
          {/* </instancedMesh> */}
        </RigidBody>
      </group>
      <group position={[-1, -1.3, 0]} rotation={[0, Math.PI * 0.5, 0]}>
        <RigidBody
          name="rampa"
          type="fixed"
          colliders={"hull"}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 102, estado: true, tipo: "rampa2" });
            }
          }}
          onCollisionExit={(event) => {
            // console.log("collision exit1", event);
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 102, estado: false, tipo: "rampa2" });
            }
          }}
          friction={-2.55}
        >
          <RampaBaranda
            // position={[-7, -1.5, 1]}
            scale={[0.1, 0.13, 0.19]}
            rotation={[0, Math.PI * 1, 0]}
          />
        </RigidBody>
      </group>
      <group position={[24, -1.3, 0]} rotation={[0, Math.PI * 0.5, 0]}>
        <RigidBody
          name="rampa"
          type="fixed"
          colliders={"hull"}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 103, estado: true, tipo: "rampa2" });
            }
          }}
          onCollisionExit={(event) => {
            // console.log("collision exit1", event);
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 103, estado: false, tipo: "rampa2" });
            }
          }}
          friction={-2.55}
        >
          <RampaBaranda
            // position={[-7, -1.5, 1]}
            scale={[0.1, 0.15, 0.19]}
            rotation={[0, 0, 0]}
          />
        </RigidBody>
      </group>
      {coin1 && (
        <group position={[-4, 1.7, 0]}>
          <RigidBody
            type="fixed"
            colliders={false}
            sensor={true}
            onIntersectionEnter={(event) => {
              if (!event.rigidBody.userData.name === "personaje") return;
              if (refEstadoCoin1.current) return;
              setCoin1(false);
              // console.log("Goal!");
              contadorCoins();
              refEstadoCoin1.current = true;
              soundCoin.play();
            }}
            onIntersectionExit={(event) => {
              soundCoin.stop();
            }}
            // includeInvisible={true}
          >
            <CuboidCollider
              args={[0.5, 0.5, 0.5]}
              position={[0, 0, 0]}
              // visible={false}
            />
            <Coin1 />
          </RigidBody>
        </group>
      )}
      <group position={[16, -1.76, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <RigidBody
          name="rampa"
          type="fixed"
          // rotation={[-Math.PI / 2, 0, 0]}
          colliders={"hull"}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 104, estado: true, tipo: "rampa3" });
            }
          }}
          onCollisionExit={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({
                id: 104,
                estado: false,
                tipo: "rampa3 ",
              });
            }
          }}
          friction={-2.55}
        >
          <RampaBaranda
            // position={[-7, -1.5, 1]}
            scale={[0.15, 0.17, 0.31]}
            // rotation={[Math.PI * 2, Math.PI * 1.5, 0]}
          />
        </RigidBody>
      </group>
      {coin2 && (
        <group position={[25, 3, 0]}>
          <RigidBody
            type="fixed"
            colliders={false}
            sensor={true}
            onIntersectionEnter={(event) => {
              if (!event.rigidBody.userData.name === "personaje") return;
              if (refEstadoCoin2.current) return;
              setCoin2(false);
              contadorCoins();
              soundCoin.play();

              refEstadoCoin2.current = true;
            }}
            onIntersectionExit={(event) => {
              soundCoin.stop();
            }}
            // includeInvisible={true}
          >
            <CuboidCollider
              args={[0.5, 0.5, 0.5]}
              position={[0, 0, 0]}
              // visible={false}
            />
            <Coin1 />
          </RigidBody>
        </group>
      )}
      <group position={[83, -1.4, 11.5]} rotation={[0, Math.PI * 0.5, 0]}>
        <RigidBody type="fixed" colliders={false}>
          <CuboidCollider args={[1.5, 2, 1.5]} position={[0, 2, 0]} />
          <CartelFlecha />
        </RigidBody>
      </group>
    </group>
  );
};

export default GrupoRampas;
