import ProgressBar from "progressbar.js";
import { useEffect, useRef } from "react";
import "./loading.css";
const Loading = ({ porcentaje, sendTerminoLoading }) => {
  // progressbar.js@1.0.0 version is used
  // Docs: http://progressbarjs.readthedocs.org/en/1.0.0/
  const refBar = useRef(null);
  useEffect(() => {
    refBar.current = new ProgressBar.Path("#heart-path", {
      easing: "easeInOut",
      duration: 100,
    });

    refBar.current.set(0);
    // bar.animate(1); // Number from 0.0 to 1.0
  }, []);

  useEffect(() => {
    // 100 = 1
    const temporal = porcentaje / 100;
    // console.log("temporal: ", temporal);
    // refBar.current.set(temporal);
    // refBar.current.animate(temporal)
    // setTimeout(() => {
    refBar.current.animate(
      temporal,
      {
        // duration: 2000,
        duration: 10,
      },
      function () {
        // console.log("termino: ");
        if (parseFloat(temporal) === 1) {
          sendTerminoLoading(true);
        }
      }
    );
    // }, 1000);
    // const valor = refBar.current.value();
    // console.log("valor: ", valor);
  }, [porcentaje]);
  return (
    <div className="d-flex justify-content-center" id="contenedor">
      <div className="d-flex flex-column">
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          // width="160.000000pt"
          // height="320.000000pt"
          viewBox="0 0 1600.000000 1300.000000"
          // preserveAspectRatio="xMidYMid meet"
          style={{
            // left: "20%",
            position: "relative",
            width: "100%",
            height: "auto",
            zIndex: "1050",
            //   height: "95%",
            margin: "0 auto",
            display: "block",
          }}
          // width="100%"
          // height="auto"
        >
          <g
            transform="
          translate(1280.000000,1280.000000)
          scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              fillOpacity="0"
              strokeWidth="200"
              stroke="#bbb"
              d="M2930 12789 c-467 -38 -784 -180 -977 -437 -150 -198 -245 -473 -288
-824 -16 -131 -27 -533 -45 -1663 -6 -368 -20 -1178 -31 -1800 -24 -1296 -45
-6340 -28 -6632 46 -823 331 -1235 945 -1372 345 -77 861 -75 1250 5 285 58
460 142 622 298 244 235 358 599 377 1201 3 105 10 678 15 1275 10 1044 19
2030 40 4290 38 4122 38 4133 29 4295 -38 629 -224 993 -604 1182 -44 22 -107
50 -140 61 -287 100 -787 151 -1165 121z m670 -34 c266 -32 448 -78 615 -159
324 -156 498 -421 574 -877 41 -248 44 -366 32 -1559 -6 -619 -16 -1618 -21
-2220 -6 -602 -15 -1534 -20 -2070 -5 -536 -13 -1387 -17 -1890 -5 -507 -10
-835 -13 -735 -4 121 -10 188 -19 205 -31 57 -67 93 -115 116 -44 21 -66 24
-162 24 -103 0 -116 -2 -165 -28 -34 -17 -65 -44 -84 -71 -28 -39 -30 -50 -33
-142 l-4 -99 -66 0 c-125 0 -326 39 -409 78 l-33 16 0 233 0 233 -445 0 -445
0 0 -193 0 -193 -32 -31 c-18 -17 -46 -38 -63 -46 -82 -41 -263 -75 -413 -78
l-93 -2 -3 79 c-2 44 -12 98 -21 122 -20 50 -84 108 -134 122 -46 13 -225 13
-271 0 -47 -13 -106 -63 -133 -113 -21 -40 -22 -56 -25 -318 -2 -171 1 -298 7
-334 14 -76 48 -127 108 -162 43 -25 60 -28 155 -31 132 -5 192 12 249 70 41
43 57 79 63 148 l4 45 301 3 301 2 0 -200 0 -200 445 0 445 0 0 200 0 200 253
-2 252 -3 6 -68 c7 -88 42 -141 118 -179 50 -26 64 -28 165 -28 96 0 118 3
162 24 58 27 99 74 120 138 13 38 14 -39 9 -617 -7 -817 -19 -982 -85 -1241
-168 -651 -599 -898 -1570 -899 -573 0 -917 106 -1156 357 -87 91 -124 145
-179 257 -99 201 -150 417 -175 751 -13 173 -5 4553 11 5560 18 1193 28 1825
35 2125 6 273 8 296 21 260 16 -47 64 -100 115 -126 31 -16 58 -19 160 -19
140 0 184 14 232 71 44 52 56 90 56 180 l0 82 128 -6 c197 -9 362 -49 432
-106 l35 -28 5 -204 5 -204 445 0 445 0 3 236 2 237 53 21 c74 30 223 59 343
67 l101 7 5 -89 c6 -102 27 -155 80 -199 59 -50 90 -57 228 -53 118 3 129 5
168 31 23 15 54 46 69 69 l28 42 0 342 0 342 -31 44 c-50 68 -107 92 -228 96
-56 2 -120 -1 -142 -7 -96 -26 -160 -106 -171 -216 l-6 -55 -248 0 -249 0 0
200 0 200 -447 -2 -448 -3 -3 -197 -2 -198 -300 0 -300 0 0 45 c0 84 -45 156
-125 198 -34 18 -57 22 -160 22 -110 0 -125 -2 -170 -25 -34 -18 -61 -42 -84
-75 l-33 -50 6 270 c3 149 8 428 11 620 3 193 10 413 16 490 45 644 247 1039
629 1230 178 89 330 125 665 159 86 9 507 -4 635 -19z"
            />
            <path
              id="heart-path"
              fillOpacity="0"
              strokeWidth="200"
              stroke="#A91EE4"
              d="M2930 12789 c-467 -38 -784 -180 -977 -437 -150 -198 -245 -473 -288
-824 -16 -131 -27 -533 -45 -1663 -6 -368 -20 -1178 -31 -1800 -24 -1296 -45
-6340 -28 -6632 46 -823 331 -1235 945 -1372 345 -77 861 -75 1250 5 285 58
460 142 622 298 244 235 358 599 377 1201 3 105 10 678 15 1275 10 1044 19
2030 40 4290 38 4122 38 4133 29 4295 -38 629 -224 993 -604 1182 -44 22 -107
50 -140 61 -287 100 -787 151 -1165 121z m670 -34 c266 -32 448 -78 615 -159
324 -156 498 -421 574 -877 41 -248 44 -366 32 -1559 -6 -619 -16 -1618 -21
-2220 -6 -602 -15 -1534 -20 -2070 -5 -536 -13 -1387 -17 -1890 -5 -507 -10
-835 -13 -735 -4 121 -10 188 -19 205 -31 57 -67 93 -115 116 -44 21 -66 24
-162 24 -103 0 -116 -2 -165 -28 -34 -17 -65 -44 -84 -71 -28 -39 -30 -50 -33
-142 l-4 -99 -66 0 c-125 0 -326 39 -409 78 l-33 16 0 233 0 233 -445 0 -445
0 0 -193 0 -193 -32 -31 c-18 -17 -46 -38 -63 -46 -82 -41 -263 -75 -413 -78
l-93 -2 -3 79 c-2 44 -12 98 -21 122 -20 50 -84 108 -134 122 -46 13 -225 13
-271 0 -47 -13 -106 -63 -133 -113 -21 -40 -22 -56 -25 -318 -2 -171 1 -298 7
-334 14 -76 48 -127 108 -162 43 -25 60 -28 155 -31 132 -5 192 12 249 70 41
43 57 79 63 148 l4 45 301 3 301 2 0 -200 0 -200 445 0 445 0 0 200 0 200 253
-2 252 -3 6 -68 c7 -88 42 -141 118 -179 50 -26 64 -28 165 -28 96 0 118 3
162 24 58 27 99 74 120 138 13 38 14 -39 9 -617 -7 -817 -19 -982 -85 -1241
-168 -651 -599 -898 -1570 -899 -573 0 -917 106 -1156 357 -87 91 -124 145
-179 257 -99 201 -150 417 -175 751 -13 173 -5 4553 11 5560 18 1193 28 1825
35 2125 6 273 8 296 21 260 16 -47 64 -100 115 -126 31 -16 58 -19 160 -19
140 0 184 14 232 71 44 52 56 90 56 180 l0 82 128 -6 c197 -9 362 -49 432
-106 l35 -28 5 -204 5 -204 445 0 445 0 3 236 2 237 53 21 c74 30 223 59 343
67 l101 7 5 -89 c6 -102 27 -155 80 -199 59 -50 90 -57 228 -53 118 3 129 5
168 31 23 15 54 46 69 69 l28 42 0 342 0 342 -31 44 c-50 68 -107 92 -228 96
-56 2 -120 -1 -142 -7 -96 -26 -160 -106 -171 -216 l-6 -55 -248 0 -249 0 0
200 0 200 -447 -2 -448 -3 -3 -197 -2 -198 -300 0 -300 0 0 45 c0 84 -45 156
-125 198 -34 18 -57 22 -160 22 -110 0 -125 -2 -170 -25 -34 -18 -61 -42 -84
-75 l-33 -50 6 270 c3 149 8 428 11 620 3 193 10 413 16 490 45 644 247 1039
629 1230 178 89 330 125 665 159 86 9 507 -4 635 -19z"
            />
          </g>
        </svg>
        <p>
          <i>Loading...</i>
        </p>
      </div>
    </div>
  );
};

export default Loading;
