import "./button2.css";
const Button2 = (props) => {
  return (
    <button
      className="button-86 mx-auto d-block"
      role="button"
      // ref={props.ref2}
      // onClick={props.onClick}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button2;
