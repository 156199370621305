import { CuboidCollider, RigidBody } from "@react-three/rapier";
import RampaBaranda from "../Rampas/RampaBaranda";
import Baranda1 from "./../Barandas/baranda1";
import { Gapless5 } from "@regosen/gapless-5";
import soundGrind from "./../sonidos/skate/grindrail2.mp3";
import { useEffect, useRef, useState } from "react";
import archivoSoundCoin from "./../sonidos/coin.mp3";
import CartelFlecha from "../Carteles/CartelFlecha";
import Coin1 from "../Coins/Coin";
import useSound from "use-sound";
import { isMobile } from "react-device-detect";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useMatcapTexture } from "@react-three/drei";
const GrupoBarandas = ({
  funcionActualizarPiso,
  positionGroup,
  rotationGroup,
  girarTabla,
  contadorCoins,
}) => {
  const { camera } = useThree();
  const [coin1, setCoin1] = useState(true);
  const [coin2, setCoin2] = useState(true);
  // const [playSoundCoin, { stop: stopSoundCoin }] = useSound(archivoSoundCoin, {
  //   volume: 0.5,
  // });
  // const [playSoundGrind, { stop: stopSoundGrind }] = useSound(soundGrind, {
  //   loop: true,
  //   volume: 0.5,
  // });

  const [listenerCoin] = useState(() => new THREE.AudioListener());
  const [soundCoin] = useState(() => new THREE.Audio(listenerCoin));
  const [listenerGrind] = useState(() => new THREE.AudioListener());
  const [soundGrind] = useState(() => new THREE.Audio(listenerGrind));
  // const [sonidoCoin, setSonidoCoin] = useState(new Gapless5());
  // const refSonidoCoin = useRef(
  //   new Gapless5({
  //     loop: true,
  //     tracks: [archivoSoundCoin],
  //   })
  // );
  useEffect(() => {
    /**
     *
     * Sonido coin
     *
     */
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load("./sonidos/coin.mp3", function (buffer) {
      camera.add(listenerCoin);
      soundCoin.setBuffer(buffer);
      // soundCoin.setLoop(true);
      soundCoin.setVolume(0.6);
    });
    /**
     *
     *
     * Sonido grind
     *
     *
     */
    const audioLoader2 = new THREE.AudioLoader();
    audioLoader2.load("./sonidos/skate/grindrail2.mp3", function (buffer) {
      camera.add(listenerGrind);
      soundGrind.setBuffer(buffer);
      soundGrind.setLoop(true);
      soundGrind.setVolume(0.7);
    });
  }, []);
  const refEstadoCoin1 = useRef(false);
  const refEstadoCoin2 = useRef(false);
  // const refSonidoSkateGrind = useRef(
  //   new Gapless5({
  //     loop: true,
  //     tracks: [soundGrind],
  //   })
  // );
  return (
    <group position={positionGroup} rotation={rotationGroup}>
      {/* <group position={[16, 0, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <RigidBody
          type="fixed"
          // rotation={[-Math.PI / 2, 0, 0]}
          colliders={"hull"}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 104, estado: true, tipo: "rampa3" });
            }
          }}
          onCollisionExit={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({
                id: 104,
                estado: false,
                tipo: "rampa3 ",
              });
            }
          }}
          friction={-2.55}
        >
          <RampaBaranda
            // position={[-7, -1.5, 1]}
            scale={[0.15, 0.17, 0.33]}
            // rotation={[Math.PI * 2, Math.PI * 1.5, 0]}
          />
        </RigidBody>
      </group> */}
      <group position={[-10, 0, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <RigidBody
          name="rampa"
          type="fixed"
          // rotation={[-Math.PI / 2, 0, 0]}
          colliders={"hull"}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 110, estado: true, tipo: "rampa" });
            }
          }}
          onCollisionExit={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({
                id: 110,
                estado: false,
                tipo: "rampa",
              });
            }
          }}
          friction={-2.55}
        >
          <RampaBaranda
            // position={[-7, -1.5, 1]}
            scale={[0.1, 0.13, 0.13]}
            rotation={[0, Math.PI * 1, 0]}
          />
        </RigidBody>
      </group>
      {coin1 && (
        <group position={[-6, 3.7, 0]}>
          <RigidBody
            type="fixed"
            colliders={false}
            sensor={true}
            onIntersectionEnter={(event) => {
              if (!event.rigidBody.userData.name === "personaje") return;
              if (refEstadoCoin1.current) return;
              setCoin1(false);
              // console.log("Goal!");
              contadorCoins();

              refEstadoCoin1.current = true;
              // refSonidoCoin.current.loop = false;
              // refSonidoCoin.current = new Gapless5();
              // refSonidoCoin.current.addTrack(archivoSoundCoin);
              // refSonidoCoin.current.play();
              soundCoin.play();
            }}
            // includeInvisible={true}
            onIntersectionExit={(event) => {
              // refSonidoCoin.current.stop();
              soundCoin.stop();
            }}
          >
            <CuboidCollider
              args={[0.5, 0.5, 0.5]}
              position={[0, 0, 0]}
              // visible={false}
            />
            <Coin1 />
          </RigidBody>
        </group>
      )}
      <group position={[8.5, 2, 0]}>
        <RigidBody
          type="fixed"
          // position-y={0.47}
          // position={[-0.5, 1.5, 90]}
          // rotation={[-Math.PI / 2, 0, 0]}
          colliders={false}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 201, estado: true, tipo: "baranda" });
            }
          }}
          onCollisionExit={(event) => {
            // console.log("collision exit3", event);
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({
                id: 201,
                estado: false,
                tipo: "baranda",
              });
            }
          }}
          friction={-2.55}
        >
          <CuboidCollider
            args={[8, 0.6, 0.46]}
            position={[0, -0.4, 0]}
            rotation-y={Math.PI}
            name="personaje2"
            // visible={false}
          />
          <Baranda1 />
        </RigidBody>
        <RigidBody
          sensor={true}
          type="fixed"
          onIntersectionEnter={(event) => {
            if (!event.rigidBody.userData.name === "personaje") return;
            girarTabla(true);
            soundGrind.play();
            // refSonidoSkateGrind.current.play();
          }}
          onIntersectionExit={(event) => {
            if (!event.rigidBody.userData.name === "personaje") return;
            // console.log("No Deslizando");
            girarTabla(false);
            // refSonidoSkateGrind.current.stop();
            soundGrind.stop();
          }}
        >
          <CuboidCollider
            args={[8, 0.3, 0.3]}
            position={[0, 0.2, 0]}
            rotation-y={Math.PI}
            name="personaje2"
            // visible={false}
          />
        </RigidBody>
      </group>
      {/* Segunda rampa con baranda */}
      <group position={[50, 0, 0]} rotation={[0, Math.PI * 1.5, 0]}>
        <RigidBody
          name="rampa"
          type="fixed"
          // rotation={[-Math.PI / 2, 0, 0]}
          colliders={"hull"}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 111, estado: true, tipo: "rampa" });
            }
          }}
          onCollisionExit={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({
                id: 111,
                estado: false,
                tipo: "rampa",
              });
            }
          }}
          friction={-3}
        >
          <RampaBaranda
            // position={[-7, -1.5, 1]}
            scale={[0.1, 0.13, 0.13]}
            rotation={[0, Math.PI * 1, 0]}
          />
        </RigidBody>
      </group>
      {coin2 && (
        <group position={[70, 3.7, 0]}>
          <RigidBody
            type="fixed"
            colliders={false}
            sensor={true}
            onIntersectionEnter={(event) => {
              if (!event.rigidBody.userData.name === "personaje") return;
              if (refEstadoCoin2.current) return;
              setCoin2(false);
              contadorCoins();
              refEstadoCoin2.current = true;
              // refSonidoCoin.current.loop = false;
              // refSonidoCoin.current = new Gapless5();
              // refSonidoCoin.current.addTrack(archivoSoundCoin);
              // refSonidoCoin.current.play();
              soundCoin.play();
            }}
            // includeInvisible={true}
            onIntersectionExit={(event) => {
              // refSonidoCoin.current.stop();
              soundCoin.stop();
            }}
          >
            <CuboidCollider
              args={[0.5, 0.5, 0.5]}
              position={[0, 0, 0]}
              // visible={false}
            />
            <Coin1 />
          </RigidBody>
        </group>
      )}
      <group position={[70, 2, 0]}>
        <RigidBody
          type="fixed"
          // position-y={0.47}
          // position={[-0.5, 1.5, 90]}
          // rotation={[-Math.PI / 2, 0, 0]}
          colliders={false}
          onCollisionEnter={(event) => {
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({ id: 202, estado: true, tipo: "baranda" });
            }
          }}
          onCollisionExit={(event) => {
            // console.log("collision exit3", event);
            if (event.rigidBody.userData.name === "personaje") {
              funcionActualizarPiso({
                id: 202,
                estado: false,
                tipo: "baranda",
              });
            }
          }}
          friction={-3}
        >
          <CuboidCollider
            args={[8, 0.6, 0.46]}
            position={[0, -0.4, 0]}
            rotation-y={Math.PI}
            name="personaje2"
            // visible={false}
          />
          <Baranda1 />
        </RigidBody>
        <RigidBody
          sensor={true}
          type="fixed"
          onIntersectionEnter={(event) => {
            if (!event.rigidBody.userData.name === "personaje") return;
            girarTabla(true);
            soundGrind.play();
            // refSonidoSkateGrind.current.play();
          }}
          onIntersectionExit={(event) => {
            if (!event.rigidBody.userData.name === "personaje") return;
            girarTabla(false);
            soundGrind.stop();
            // refSonidoSkateGrind.current.stop();
          }}
        >
          <CuboidCollider
            args={[8, 0.3, 0.3]}
            position={[0, 0.2, 0]}
            rotation-y={Math.PI}
            name="personaje2"
            // visible={false}
          />
        </RigidBody>
      </group>

      {!isMobile && (
        <group position={[110, 0.5, 2]} rotation={[0, Math.PI * 0.5, 0]}>
          <RigidBody type="fixed" colliders={false}>
            <CuboidCollider args={[1.5, 2, 1.5]} position={[0, 2, 0]} />
            <CartelFlecha />
          </RigidBody>
        </group>
      )}
    </group>
  );
};

export default GrupoBarandas;
