import "./../styles/style-cronometro.css";
import { useStopwatch, useTimer } from "react-use-precision-timer";
import { useCallback, useEffect, useRef, useState } from "react";
const CronometroFinal = ({
  activarTimer,
  desactivarTimer,
  sendTiempo,
  stopTimer,
}) => {
  // const stopwatch = useStopwatch();
  // const [time, setTime] = useState(stopwatch.getElapsedRunningTime());
  // const refStopWatch = useRef(useStopwatch());
  // const [stopWatch, setStopWatch] = useState(
  //   useTimer({
  //     // precision: 100,
  //     callback() {
  //       console.log("callback");
  //       setTime(stopWatch.getElapsedRunningTime());
  //     },
  //   })
  // );

  const callback = useCallback((e) => {
    // console.log("Boom", e);
    // console.log("time", timer.getElapsedRunningTime());
    const temporal = timer.getElapsedRunningTime();
    setTime(temporal);
    sendTiempo(temporal);
    setTime(temporal);
  }, []);
  // The callback will be called every 1000 milliseconds.
  const timer = useTimer({ delay: 1000 }, callback);

  const [time, setTime] = useState(0);

  // useEffect(() => {
  // timer.start();
  // if (!stopWatch) return;
  // stopwatch.start();
  // setTime(stopwatch.getElapsedRunningTime());
  // setInterval(() => {
  //   setTime(stopWatch.getElapsedRunningTime());
  // }, 100);
  // }, []);
  useEffect(() => {
    if (activarTimer) {
      timer.start();
    }
  }, [activarTimer]);

  useEffect(() => {
    if (desactivarTimer) {
      timer.pause();
      const temporal = timer.getElapsedRunningTime();
      sendTiempo(temporal);
      setTime(temporal);
    }
  }, [desactivarTimer]);

  // useEffect(() => {
  // const temporal = timer.getElapsedRunningTime();
  // sendTiempo(temporal);
  // setTime(temporal);
  // }, [time]);

  useEffect(() => {
    if (stopTimer) {
      timer.pause();
    }
  }, [stopTimer]);
  return (
    <div className="stop-watch">
      <Timer time={time} />
      {/* {stopWatch.getElapsedRunningTime()} */}
    </div>
  );
};
function Timer(props) {
  return (
    <div className="timer">
      <span className="digits">
        {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
      </span>
      <span className="digits">
        {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
      </span>
      {/* <span className="digits mili-sec">
        {("0" + ((props.time / 10) % 100)).slice(-2)}
      </span> */}
    </div>
  );
}
export default CronometroFinal;
