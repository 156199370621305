/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
function CartelMensaje(props) {
  const [material, setMaterial] = useState(null);
  const { nodes, materials } = useGLTF("./carteles/cartel1.glb");
  return (
    <RigidBody type="fixed" colliders={false}>
      <CuboidCollider args={[3.5, 3, 0.5]} position={[0, 0.4, 0]} />
      <group
        {...props}
        dispose={null}
        position={[0, 0.2, 0]}
        scale={[0.08, 0.05, 0.08]}
      >
        <meshBasicMaterial color={"#952bff"} ref={setMaterial} opacity={1} />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.patas.geometry}
          // material={nodes.patas.material}
          material={
            new THREE.MeshBasicMaterial({
              // map: colorTexture,
              color: "black",
            })
          }
          rotation={[Math.PI / 2, 0, 0]}
          // scale={0.01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.letrero.geometry}
          // material={nodes.letrero.material}
          //set material no affect by light

          // material={
          //   new THREE.MeshBasicMaterial({
          //     color: "#952bff",
          //   })
          // }
          material={material}
          position={[0, -0.31, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          // scale={0.01}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("./carteles/cartel1.glb");

export default CartelMensaje;
