import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { THREEx } from "./../librerias/threex.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

const CanvasCSS = ({ cartel1, sendControls }) => {
  const refRendererCSS = useRef(null);
  const refUpdateFcts = useRef([]);
  const refControls = useRef(null);
  const set = useThree((state) => state.set);
  const get = useThree((state) => state.get);
  useEffect(() => {
    // get().scene.background = new THREE.Color(0x000000);
    const scene = get().scene;
    const renderer = get().gl;
    const camera = get().camera;
    camera.position.set(-29.3, 0.1, 90.2);
    camera.lookAt(-29.3, 0.1, 90.2);

    camera.near = 1;
    // set({ scene: scene });
    const mixerContext = new THREEx.HtmlMixer.Context(renderer, scene, camera);

    // set up rendererCss
    const rendererCss = mixerContext.rendererCss;
    rendererCss.setSize(window.innerWidth, window.innerHeight);
    // set webgl
    const rendererWebgl = mixerContext.rendererWebgl;

    const css3dElement = rendererCss.domElement;
    css3dElement.style.position = "absolute";
    css3dElement.style.top = "0px";
    css3dElement.style.width = "100%";
    css3dElement.style.height = "100%";
    css3dElement.id = "prueba1";
    //   css3dElement.style.zIndex = "99999";
    //   css3dElement.style.pointerEvents = "none";

    // document.getElementById("canvas2").appendChild(css3dElement);
    // document.body.appendChild(css3dElement);
    // rendererCss.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    // rendererCss.devicePixelRatio = Math.min(window.devicePixelRatio, 2);
    const webglCanvas = rendererWebgl.domElement;
    webglCanvas.style.position = "relative";
    webglCanvas.style.top = "0px";
    webglCanvas.style.width = "100%";
    webglCanvas.style.height = "100%";
    webglCanvas.style.zIndex = "40";
    webglCanvas.style.pointerEvents = "none";
    // document.querySelector("canvas").appendChild(css3dElement);
    const parentTemporal1 = webglCanvas.parentElement;
    const parentTemporal2 = parentTemporal1.parentElement;
    const parentTemporal3 = parentTemporal2.parentElement;
    // const parentTemporal4 = parentTemporal3.parentElement;
    parentTemporal3.appendChild(css3dElement);
    // document.body.appendChild(css3dElement);
    // css3dElement.appendChild(webglCanvas);
    /**
     *
     * Prueba de CSS3DObject
     *
     *
     */
    // const domElement = document.createElement("div");
    // domElement.innerHTML = "Prueba uno";
    // domElement.style.background = "green";
    // domElement.style.cursor = "pointer";
    cartel1.style.display = "block";
    const domElement = cartel1;
    const mixerPlane = new THREEx.HtmlMixer.Plane(mixerContext, domElement, {
      planeW: 5.5,
      planeH: 2.15,
      elementW: 900,
    });
    mixerPlane.object3d.position.x = 0;
    mixerPlane.object3d.position.y = 4;
    mixerPlane.object3d.position.z = 0.1;

    scene.add(mixerPlane.object3d);
    refUpdateFcts.current.push(function (delta, now) {
      // NOTE: it must be after camera mode
      mixerContext.update(delta, now);
    });
    //ad mesh boxgeometry red
    const geometry = new THREE.BoxGeometry(12, 12, 12);
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const cube = new THREE.Mesh(geometry, material);
    cube.position.x = 40;
    cube.position.y = 0.5;
    cube.position.z = 0;
    // scene.add(cube);
    refControls.current = new OrbitControls(camera, css3dElement);
    // refControls.current.target.set(0, 4, 0.1);
    refControls.current.enableZoom = false;
    refControls.current.enableRotate = false;
    sendControls(refControls.current);
    let lastTimeMsec = null;
    refRendererCSS.current = rendererCss;
    requestAnimationFrame(function tick(nowMsec) {
      window.requestAnimationFrame(tick);

      // measure time
      // lastTimeMsec = lastTimeMsec || nowMsec - 1000 / 60;
      // var deltaMsec = Math.min(200, nowMsec - lastTimeMsec);
      // lastTimeMsec = nowMsec;
      // call each update function
      refUpdateFcts.current.forEach(function (updateFn) {
        updateFn();
      });

      // refRendererCss.current.setSize(canvas.clientWidth, canvas.clientHeight);
    });
  }, []);
  const canvas = document.querySelector("canvas");
  useFrame(() => {
    // // call each update function
    // refUpdateFcts.current.forEach(function (updateFn) {
    // updateFn(deltaMsec / 1000, nowMsec / 1000);
    //   updateFn();
    // });
    if (!refRendererCSS.current || !refControls.current) return;
    refControls.current.update();
    refRendererCSS.current.setSize(canvas.clientWidth, canvas.clientHeight);
  });
  let lastTimeMsec = null;
  // useFrame(() => {
  //   refUpdateFcts.current.forEach(function (updateFn) {
  //     updateFn();
  //     console.log("update");
  //   });
  //   refRendererCSS.current.setSize(canvas.clientWidth, canvas.clientHeight);
  // });

  return null;
};

export default CanvasCSS;
