import "./button1.css";
const Button1 = (props) => {
  return (
    <button
      className="button-85 mx-auto d-block"
      role="button"
      ref={props.ref2}
    >
      {props.children}
    </button>
  );
};

export default Button1;
