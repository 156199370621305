import { Text3D, OrbitControls, useMatcapTexture } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
const TextoSoweme = () => {
  const { camera } = useThree();
  const refS = useRef(null);
  const refTextoS = useRef(null);
  const refO = useRef(null);
  const refTextoO = useRef(null);
  const refW = useRef(null);
  const refTextoW = useRef(null);
  const refE = useRef(null);
  const refTextoE = useRef(null);
  const refM = useRef(null);
  const refTextoM = useRef(null);
  const refE2 = useRef(null);
  const refTextoE2 = useRef(null);
  const posicionLetra = [-0.5, -0.8, -1];
  const fuenteLetra = "./fonts/comic.json";
  const tamanioLetra = 1.7;
  const heightLetra = 0.2;
  const [material, setMaterial] = useState();
  const [matcapTexture] = useMatcapTexture("1C70C6_09294C_0F3F73_52B3F6", 64);

  // useFrame(() => {
  // refTextoS.current.position.copy(refS.current.translation());
  // refTextoS.current.quaternion.copy(refS.current.rotation());
  // refTextoO.current.position.copy(refO.current.translation());
  // refTextoO.current.quaternion.copy(refO.current.rotation());
  // refTextoW.current.position.copy(refW.current.translation());
  // refTextoW.current.quaternion.copy(refW.current.rotation());
  // refTextoE.current.position.copy(refE.current.translation());
  // refTextoE.current.quaternion.copy(refE.current.rotation());
  // refTextoM.current.position.copy(refM.current.translation());
  // refTextoM.current.quaternion.copy(refM.current.rotation());
  // refTextoE2.current.position.copy(refE2.current.translation());
  // refTextoE2.current.quaternion.copy(refE2.current.rotation());
  // });
  return (
    <group
      // position={[-24, 1, 87.25]}
      position={[-20, 2, 0]}
      rotation={[0, Math.PI * 0.5, 0]}
    >
      <meshMatcapMaterial ref={setMaterial} matcap={matcapTexture} />
      <RigidBody
        // ref={refS}
        type="dynamic"
        position={[0, 2, -0.55]}
        rotation={[0, Math.PI * 1.5, 0]}
        // mass={0.001}
        friction={1}
        // colliders={false}
        colliders={false}
        // restitution={2}
      >
        <CuboidCollider args={[0.7, 0.8, 0.5]} position={[0.2, 0, -0.8]} />
        <Text3D
          font={fuenteLetra}
          // position={[-30, 2.5, 0]}
          position={posicionLetra}
          // position={[-0.5, -0.8, -10]}
          size={tamanioLetra}
          height={heightLetra}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
          material={material}
        >
          S
        </Text3D>
      </RigidBody>
      <group ref={refTextoS}></group>
      <RigidBody
        // ref={refO}
        type="dynamic"
        // position={[-24, 2.5, 87.25]}
        position={[0, 2, 0.88]}
        rotation={[0, Math.PI * 1.5, 0]}
        // mass={0.001}
        friction={1}
        colliders={false}
      >
        <CuboidCollider args={[0.7, 0.8, 0.5]} position={[0.2, 0, -0.8]} />
        <Text3D
          font={fuenteLetra}
          // position={[-30, 2.5, 0]}
          position={posicionLetra}
          size={tamanioLetra}
          height={heightLetra}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
          material={material}
        >
          O
        </Text3D>
      </RigidBody>
      {/* <group ref={refTextoO}> */}
      {/* </group> */}
      <RigidBody
        // ref={refW}
        type="dynamic"
        // position={[-24, 2.5, 89.2]}
        position={[0, 2, 2.52]}
        rotation={[0, Math.PI * 1.5, 0]}
        angularDamping={1}
        // mass={0.001}
        colliders={false}
        friction={1}
      >
        <CuboidCollider args={[1.2, 0.8, 0.5]} position={[0.4, 0, -0.9]} />
        <Text3D
          font={fuenteLetra}
          // position={[-30, 2.5, 0]}
          position={[-0.65, -0.8, -1]}
          size={tamanioLetra}
          height={heightLetra}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
          material={material}
        >
          W
        </Text3D>
      </RigidBody>
      {/* <group ref={refTextoW}> */}
      {/* </group> */}
      <RigidBody
        // ref={refE}
        type="dynamic"
        // position={[-24, 2.5, 91.3]}
        position={[0, 2, 4.58]}
        rotation={[0, Math.PI * 1.5, 0]}
        // mass={0.001}
        friction={1}
        colliders={false}
      >
        <CuboidCollider args={[0.7, 0.8, 0.5]} position={[0.4, 0, -0.9]} />
        <Text3D
          font={fuenteLetra}
          // position={[-30, 2.5, 0]}
          position={posicionLetra}
          size={tamanioLetra}
          height={heightLetra}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
          material={material}
        >
          E
        </Text3D>
      </RigidBody>
      {/* <group ref={refTextoE}> */}
      {/* </group> */}
      <RigidBody
        // ref={refM}
        type="dynamic"
        // position={[-24, 2.5, 93.25]}
        position={[0, 2, 6]}
        rotation={[0, Math.PI * 1.5, 0]}
        // mass={0.001}
        friction={1}
        colliders={false}
      >
        <CuboidCollider args={[0.86, 0.8, 0.5]} position={[0.4, 0, -0.9]} />
        <Text3D
          font={fuenteLetra}
          // position={[-30, 2.5, 0]}
          position={posicionLetra}
          size={tamanioLetra}
          height={heightLetra}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
          material={material}
        >
          M
        </Text3D>
      </RigidBody>
      {/* <group ref={refTextoM}> */}
      {/* </group> */}
      <RigidBody
        // ref={refE2}
        type="dynamic"
        // position={[-24, 2.5, 95.3]}
        position={[0, 2, 7.89]}
        rotation={[0, Math.PI * 1.5, 0]}
        // mass={0.001}
        friction={1}
        colliders={false}
      >
        <CuboidCollider args={[0.7, 0.8, 0.5]} position={[0.4, 0, -0.9]} />
        <Text3D
          font={fuenteLetra}
          // position={[-30, 2.5, 0]}
          position={posicionLetra}
          size={tamanioLetra}
          height={heightLetra}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelOffset={0}
          bevelSegments={5}
          material={material}
        >
          E
        </Text3D>
      </RigidBody>
      {/* <group ref={refTextoE2}> */}
      {/* </group> */}
    </group>
  );
};

export default TextoSoweme;
